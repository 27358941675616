
import { Component, Vue } from "vue-property-decorator";
import AuthService from "services/auth.service";
import User from "models/User";

@Component
export default class LoginForm extends Vue {
  user = new User();
  message = "";
  forgotPass = false;
  doubleAuthLink = "";
  doubleAuthDialog = false;
  validCode = "";

  handleLogin() {
    if (this.user.username && this.user.password) {
      AuthService.login(this.user).then(
        () => {
          if(localStorage.getItem("double-auth")){
            localStorage.removeItem("double-auth");
            this.doubleAuthDialog = true;
          }
          if (AuthService.getRole() == "admin") {
            this.$router.push("/Administration");
          } else if (AuthService.getRole() == "company") {
            this.$router.push("/Entreprise");
          } else if (AuthService.getRole() == "super-admin") {
            this.$router.push("/Administration");
          }

        },
        (error) => {
          this.message = "erreur";
        }
      );
    }
  }
  handlePassword() {
    if(this.user.username){
      AuthService.forgotPassword(this.user.username)
    }
  }

  sendSubscribe(){
    this.$emit("subscribe", true);
  }

  forgotPassword(){
    this.forgotPass = !this.forgotPass;
  }

  confirmCode(){
    this.doubleAuthDialog = false;
    const clearCode = parseInt(this.validCode.replaceAll(" ", ""));
    AuthService.loginDouble(this.user, clearCode).then(
      () => {
        if (AuthService.getRole() == "admin") {
          this.$router.push("/Administration");
        } else if (AuthService.getRole() == "company") {
          this.$router.push("/Entreprise");
        } else if (AuthService.getRole() == "super-admin") {
          this.$router.push("/Administration");
        }
      },
      (error) => {
        this.message = "erreur";
      }
    );
  }

}
