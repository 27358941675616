
import { Component, Vue } from 'vue-property-decorator';
import TaxonomiesService from 'services/taxonomies.service'
import TrsBasicView from './BasicView.vue';
import { mixins } from 'vue-class-component';
import Accueil from './Accueil.vue';

@Component
export default class HomeView extends mixins(TrsBasicView) {

  title = "Liste des entreprises";

}
//"activites"
