
import Company from "models/Company";
import CompanyForm from "models/CompanyForm";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters} from 'vuex';
import APIService from "services/api.service";
import CompaniesService from "services/companies.service";
import VueSimpleAlert from "node_modules/vue-simple-alert";

import JsonCSV from 'vue-json-csv';
import Papa from 'papaparse';
import User from 'models/User';
import RegistrationForm from "models/RegistrationForm";
import RegistrationUserForm from "models/RegistrationForm";
import RegistrationContactForm from "models/RegistrationForm";
import RegistrationCompanyForm from "models/RegistrationForm";
import { Address, ContactInformation } from "models/common";
import authService from "services/auth.service";
import { TaxonomyTerm } from "models/Taxonomy";
import Taxonomy from 'models/Taxonomy';
import { AxiosResponse } from 'axios';

import ImportCompanies from "components/Administration/ImportCompanies.vue";

Vue.use(VueSimpleAlert);
Vue.component('downloadCsv', JsonCSV);


export function formatPhone(phone){
  let ret = "";
  phone = phone.trim().replaceAll(' ', '').replaceAll('/','').replaceAll('.','').replaceAll('\'', '').replaceAll('(', '').replaceAll(')','').replaceAll('-', '');
  if (phone[0] == '+'){
    if(phone[1] == 0 && phone.length == 13){
      phone = phone[0] + phone.slice(2,13);
    } else if(phone[3] == 0 && phone.length == 13){
      phone = phone.slice(0,3) + phone.slice(4,13);
    }
    if(phone.length == 12){
      ret = phone.slice(0, 3) + " " + phone.slice(3, 5) + " " + phone.slice(5, 8) + " " + phone.slice(8, 10) + " " + phone.slice(10, 12);
    }
  } else {
    let s = phone.slice(0, 2);
    if (s == "00"){
      if(phone.length == 13){
        ret = "+" + phone.slice(2, 4) + " " + phone.slice(4, 6) + " " + phone.slice(6, 9) + " " + phone.slice(9, 11) + " " + phone.slice(11, 13);
      }
    } else if ( s == "02" || s == "08" || s == "07" || s == "05" || s == "03" || s=="04"){
      if(phone.length == 10) {
        ret = "+41 " + phone.slice(1, 3) + " " + phone.slice(3, 6) + " " + phone.slice(6, 8) + " " + phone.slice(8, 10);
      }
    } else if ( s == "41") {
      if(phone.length == 11){
        ret = "+" + phone.slice(0, 2) + " " + phone.slice(2, 4) + " " + phone.slice(4, 7) + " " + phone.slice(7, 9) + " " + phone.slice(9, 11);
      }
    } else {
      if(phone.length == 9){
        ret = "+41 " + phone.slice(0, 2) + " " + phone.slice(2, 5) + " " + phone.slice(5, 7) + " " + phone.slice(7, 9);
      }
    }
  }
  if(ret == ""){
    console.debug("NO PHONE FOUND FOR : " + phone);
  }
  return ret;
}

export function formatDate(date) {
  let d = date.split('.');
  if (d[2] && d[1] && d[0]){
    if(parseInt(d[2], 10)< 22){
      d[2] = "20" + d[2];
    }
    return new Date(d[2], d[1], d[0])
  } else {
    let d = date.split('-');
    if (d[2] && d[1] && d[0]){
      if(parseInt(d[2], 10)< 22){
        d[2] = "20" + d[2];
      }
      return new Date(d[2], d[1], d[0])
    } else {
      let d = date.split('/');
      if (d[2] && d[1] && d[0]){
        if(parseInt(d[2], 10)< 22){
          d[2] = "20" + d[2];
        }
        return new Date(d[2], d[1], d[0])
      } else {
        console.debug("INVALID DATE : " + date);
        return null
      }
    }
  }
}

let globalData = new Vue({
  data: { $importTotal: 0,
          $importStep: 0,
          $importProg: 0 }
});
Vue.mixin({
  computed: {
    $importTotal: {
      get: function () { return globalData.$data.$importTotal },
      set: function (newData) { globalData.$data.$importTotal = newData; }
    },
    $importStep: {
      get: function () { return globalData.$data.$importStep },
      set: function (newData) { globalData.$data.$importStep = newData; }
    },
    $importProg: {
      get: function () { return globalData.$data.$importProg },
      set: function (newData) { globalData.$data.$importProg = newData; }
    }
  }
})


@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    }),
    ...mapGetters('companies', {
      companies: 'getAll',
      companyGetter: 'getOneByKey',
    }),
  },
  components: {
    ImportCompanies,
  },
})

export default class ListeAdministration extends Vue {

  selectedEntreprise = null;
  entrepriseDialog = false;
  entreprise: Company|null = null;
  entreprises_quick = [];
  filters = {};
  taxonomies!: any; // Getter from vuex for all taxonomies
  companies!: any; // Getter from vuex for all companies
  companyGetter!: any;
  loading = 0;
  totalComp = 1;

  importDialog = false;
  loadingDialog = false;

  //loadingTotal = 0;
  //loadingNbr = 0;
  //loadingTotal: localStorage.getItem("loadingTotal");
  //loadingNbr: localStorage.getItem("loadingNbr");
  csvSource = null;

  verif(liste: any, cherche: string) {
    let j: any;

    for (j in liste) {
      if (liste[j] == cherche) {
        return false;
      }
    }
    return true;
  }

  deleteEntreprise(entreprise: any) {
    this.$confirm("Voullez-vous vraiment supprimer cette entreprise ?", "Action irréversible", "warning").then(() => {
      APIService.get("/companies/delete/"+entreprise._id);
      this.$router.push('/').then(() => this.$router.push('administration/'));
    });
  }
  deleteAllEntreprise(){
    this.$confirm("Voullez-vous vraiment supprimer toutes les entreprise en attente de valdiation ?", "Action irréversible", "warning").then(() => {
      this.$confirm("Vraiment ? Il n'y a pas de possibilité de revenir en arrière !", "Action irréversible", "warning").then(() => {
        APIService.get("/companies/destroy/pending");
        this.$router.push('/').then(() => this.$router.push('administration/'));
      });
    });
  }

  //date utility
  join(t, a, s) {
   function format(m) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
   }
   return a.map(format).join(s);
  }
  a = [{day: 'numeric'}, {month: 'numeric'}, {year: 'numeric'}];

  uploadDialog(){
    this.csvSource = null;
    this.importDialog = true;
  }
  requestUploadFile(){
      this.csvSource = this.$el.querySelector('#uploadmyfile')
  }

  async importCSV(bigFile) {
    this.importDialog = false;
    this.loadingDialog = true;
    globalData.$data.$importTotal = 0;
    globalData.$data.$importProg = 0;
    globalData.$data.$importStep = 0;
    Papa.parse(this.csvSource.files[0], {
      worker: true,
      encoding: 'latin1',
      delimiter: ";",
      step: async function(row) {
        globalData.$data.$importTotal += 1;
        let regForm = new RegistrationForm();
        // USER PART
        try {
          if(row.data[15]){
            row.data[105] ? regForm.user.username = row.data[105] : regForm.user.username = row.data[0];
            row.data[106] ? regForm.user.password = row.data[106] : regForm.user.password = row.data[0];
            regForm.user.passwordConfirmation = regForm.user.password;
            row.data[14] ? regForm.user.last_name = row.data[14] : regForm.user.last_name = "";
            row.data[13] ? regForm.user.first_name = row.data[13] : regForm.user.first_name = "";
            row.data[15] ? regForm.user.email = row.data[15] : regForm.user.email = "";
            // CONTACT PART
            row.data[20] ? regForm.contact.address.street = row.data[20] : regForm.contact.address.street = "";
            row.data[22] ? regForm.contact.address.zip = row.data[22] : regForm.contact.address.zip = "";
            row.data[21] ? regForm.contact.address.city = row.data[21] : regForm.contact.address.city = "";
            row.data[17] ? regForm.contact.mobile = row.data[17] : regForm.contact.mobile = "";
            row.data[16] ? regForm.contact.telephone = formatPhone(row.data[16]) : regForm.contact.telephone = "";
            row.data[15] ? regForm.contact.email = row.data[15] : regForm.contact.email = "";
            row.data[18] ? regForm.contact.fax = formatPhone(row.data[18]) : regForm.contact.fax = "";
            row.data[14] ? regForm.contact.last_name = row.data[14] : regForm.contact.last_name = "";
            row.data[13] ? regForm.contact.first_name = row.data[13] : regForm.contact.first_name = "";
            regForm.contact.director = true;
            regForm.contact.chief = true;
          } else if (row.data[26]) {
            row.data[105] ? regForm.user.username = row.data[105] : regForm.user.username = row.data[0];
            row.data[106] ? regForm.user.password = row.data[106] : regForm.user.password = row.data[0];
            regForm.user.passwordConfirmation = regForm.user.password;
            row.data[25] ? regForm.user.last_name = row.data[25] : regForm.user.last_name = "";
            row.data[24] ? regForm.user.first_name = row.data[24] : regForm.user.first_name = "";
            row.data[26] ? regForm.user.email = row.data[26] : regForm.user.email = "";
            // CONTACT PART
            //row.data[] ? regForm.contact.address.street = row.data[] : regForm.contact.address.street = "";
            //row.data[] ? regForm.contact.address.zip = row.data[] : regForm.contact.address.zip = "";
            //row.data[] ? regForm.contact.address.city = row.data[] : regForm.contact.address.city = "";
            row.data[28] ? regForm.contact.mobile = row.data[28] : regForm.contact.mobile = "";
            row.data[27] ? regForm.contact.telephone = formatPhone(row.data[27]) : regForm.contact.telephone = "";
            row.data[26] ? regForm.contact.email = row.data[26] : regForm.contact.email = "";
            row.data[29] ? regForm.contact.fax = formatPhone(row.data[29]) : regForm.contact.fax = "";
            row.data[25] ? regForm.contact.last_name = row.data[25] : regForm.contact.last_name = "";
            row.data[24] ? regForm.contact.first_name = row.data[24] : regForm.contact.first_name = "";
            regForm.contact.director = true;
            regForm.contact.chief = true;
          } else if (row.data[33]) {
            row.data[105] ? regForm.user.username = row.data[105] : regForm.user.username = row.data[0];
            row.data[106] ? regForm.user.password = row.data[106] : regForm.user.password = row.data[0];
            regForm.user.passwordConfirmation = regForm.user.password;
            row.data[32] ? regForm.user.last_name = row.data[32] : regForm.user.last_name = "";
            row.data[31] ? regForm.user.first_name = row.data[31] : regForm.user.first_name = "";
            row.data[33] ? regForm.user.email = row.data[33] : regForm.user.email = "";
            // CONTACT PART
            row.data[39] ? regForm.contact.address.street = row.data[39] : regForm.contact.address.street = "";
            row.data[40] ? regForm.contact.address.zip = row.data[40] : regForm.contact.address.zip = "";
            row.data[41] ? regForm.contact.address.city = row.data[41] : regForm.contact.address.city = "";
            row.data[35] ? regForm.contact.mobile = row.data[35] : regForm.contact.mobile = "";
            row.data[34] ? regForm.contact.telephone = formatPhone(row.data[34]) : regForm.contact.telephone = "";
            row.data[33] ? regForm.contact.email = row.data[33] : regForm.contact.email = "";
            row.data[36] ? regForm.contact.fax = formatPhone(row.data[36]) : regForm.contact.fax = "";
            row.data[32] ? regForm.contact.last_name = row.data[32] : regForm.contact.last_name = "";
            row.data[31] ? regForm.contact.first_name = row.data[31] : regForm.contact.first_name = "";
            regForm.contact.director = true;
            regForm.contact.chief = true;
          } else {
            console.debug("NO USER FOUND FOR OLD ID " + row.data[0]);
            regForm.user.username = row.data[0];
            regForm.user.password = row.data[0];
            regForm.user.passwordConfirmation = regForm.user.password;
            regForm.user.last_name = row.data[1].trim().replace(/\w\S*/g, function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            regForm.user.first_name = row.data[1].trim().replace(/\w\S*/g, function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            regForm.user.email = row.data[0] + "@email.ch";
            // CONTACT PART
            regForm.contact.address.street = "";
            regForm.contact.address.zip = "";
            regForm.contact.address.city = "";
            regForm.contact.mobile = "";
            regForm.contact.telephone = "";
            regForm.contact.email = "";
            regForm.contact.fax = "";
            regForm.contact.last_name = "";
            regForm.contact.first_name = "";
            regForm.contact.director = true;
            regForm.contact.chief = true;
          }
          // COMPANY PART
          row.data[1] ? regForm.company.socialReason = row.data[1].trim().replace(/\w\S*/g, function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : regForm.company.socialReason = "";
          row.data[62]=="Le siège principal" ? regForm.company.siege = true : regForm.company.siege = false;
          row.data[5] ? regForm.company.address.street = row.data[5] : regForm.company.address.street = "";
          row.data[7] ? regForm.company.address.city = row.data[7] : regForm.company.address.city = "";
          row.data[8] ? regForm.company.address.zip = row.data[8] : regForm.company.address.zip = "";
          row.data[9] ? regForm.company.telephone = formatPhone(row.data[9]) : regForm.company.telephone = "";
          row.data[11] ? regForm.company.email = row.data[11] : regForm.company.email = "";
          row.data[10] ? regForm.company.fax = formatPhone(row.data[10]) : regForm.company.fax = "";
          let returnVal = await authService.register(regForm);
          globalData.$data.$importStep += 1;
          if(returnVal.status == 200){
            //FORM PART
            let company = await APIService.get('/companies/' + returnVal.data["id"]);
            globalData.$data.$importStep += 1;
            if(company.status == 200){
              let form = company.data.latest_form;
              row.data[90] ? form.AVSno = row.data[90] : form.AVSno = null;
              row.data[93] ? form.description.BURNo = row.data[93] : form.description.BURNo = null;
              row.data[99] ? form.description.NOGANo = row.data[99] : form.description.NOGANo = null;
              //form.description.activity.company_type
              row.data[52] ? form.description.activity.description = row.data[52] : form.description.activity.description = null;
              //row.data[51] ? form.description.activity.subTypes = row.data[51] : null;
              if (row.data[50]){
                try{
                  let rows = row.data[50].split(",");
                  let tax_dat = await APIService.get('/taxonomies');
                  let tax = Taxonomy.getTaxonomiesFromList(tax_dat.data);
                  let tax_act = [];
                  for (let taxitem in tax){
                    if (tax[taxitem].key == "activites"){
                      tax_act = tax[taxitem].terms
                    }
                  }
                  for(let t in rows){
                    for(let a in tax_act) {
                      if (rows[t].toLowerCase().trim().replace('  ', ' ').replace(' ', '_') == tax_act[a].value) {
                        form.description.activity.types.push(tax_act[a]);
                        }
                      }
                    }
                } catch(error) {
                  console.debug("ERROR in activity for " + row.data[50]);
                }

              }
              if(row.data[53]){
                form.description.cct.state = true;
                row.data[54] ? form.description.cct.name = row.data[54] : null;
              }
              row.data[55] ? form.description.enseigne = row.data[55] : form.description.enseigne = null;
              //form.description.legalForm
              form.description.newsletterSub = false;
              row.data[43] ? form.description.taxpayerNo = row.data[43] : form.description.taxpayerNo = null;
              //form.endOfActivity.cause
              //row.data[48] && formatDate(row.data[48]) ? form.endOfActivity.date = formatDate(row.data[48]) : form.endOfActivity.date = null;

              //import non active :
              /*row.data[48] && formatDate(row.data[48]) ? form.endOfActivity.date = formatDate(row.data[48]) : form.endOfActivity.date = formatDate('2021-12-13');
              row.data[49] ? form.endOfActivity.description = row.data[49] : form.endOfActivity.description = "Inactive sur l'ancien TrisLine."
              form.endOfActivity.cause = new TaxonomyTerm({label: "TAX.CAUSE_END_ACTIVITY.NONE.SELF", value: "none"})*/

              //end modif inactive
              row.data[91] ? form.familyAllowanceNo = row.data[91] : form.familyAllowanceNo = null;
              row.data[47] && formatDate(row.data[47]) ? form.municipalTerritory.activityStartDate = formatDate(row.data[47]) : form.municipalTerritory.activityStartDate = null;

              //form.municipalTerritory.premisesSurface
              //form.municipalTerritory.shareCapital
              row.data[12] ? form.municipalTerritory.websiteURL = row.data[12] : form.municipalTerritory.websiteURL = null;
              row.data[45] && formatDate(row.data[45]) ? form.rc_registration.date = formatDate(row.data[45]) : form.rc_registration.date = null;
              row.data[44] ? form.rc_registration.etat = row.data[44] : form.rc_registration.etat = null;
              row.data[46] ? form.rc_registration.numero = row.data[46] : form.rc_registration.numero = null;
              //form.siege.address.city
              row.data[61] ? form.siege.address.country = row.data[61] : form.siege.address.country = null;
              row.data[59] ? form.siege.address.street = row.data[59] : form.siege.address.street = null;
              row.data[60] ? form.siege.address.zip = row.data[60] : form.siege.address.zip = null;
              row.data[56] ? form.siege.email = row.data[56] : form.siege.email = null;
              row.data[58] ? form.siege.fax = formatPhone(row.data[58]) : form.siege.fax = null;
              //form.siege.mobile
              //form.siege.sign
              row.data[57] ? form.siege.telephone = formatPhone(row.data[57]) : null;
              //form.updated_at
              //form.validation_status = "validated";
              row.data[66] ? form.workforce.administration = parseInt(row.data[66], 10) : form.workforce.administration = 0;
              row.data[76] ? form.workforce.men.fulltime = parseInt(row.data[76], 10) : form.workforce.men.fulltime = 0;
              row.data[82] ? form.workforce.men.parttime = parseInt(row.data[82], 10) : form.workforce.men.parttime = 0;
              row.data[85] ? form.workforce.men.parttime2 = parseInt(row.data[85], 10) : form.workforce.men.parttime2 = 0;
              row.data[73] ? form.workforce.men.strangers = parseInt(row.data[73], 10) : form.workforce.men.strangers = 0;
              row.data[72] ? form.workforce.men.swiss = parseInt(row.data[72], 10) : form.workforce.men.swiss = 0;
              row.data[86] ? form.workforce.permits.B = parseInt(row.data[86], 10) : form.workforce.permits.B = 0;
              row.data[87] ? form.workforce.permits.C = parseInt(row.data[87], 10) : form.workforce.permits.C = 0;
              row.data[88] ? form.workforce.permits.L = parseInt(row.data[88], 10) : form.workforce.permits.L = 0;
              row.data[89] ? form.workforce.permits.other = parseInt(row.data[89], 10) : form.workforce.permits.other = 0;
              row.data[67] ? form.workforce.production = parseInt(row.data[67], 10) : form.workforce.production = 0;
              row.data[75] ? form.workforce.women.fulltime = parseInt(row.data[75], 10) : form.workforce.women.fulltime = 0;
              row.data[81] ? form.workforce.women.parttime = parseInt(row.data[81], 10) : form.workforce.women.parttime = 0;
              row.data[84] ? form.workforce.women.parttime2 = parseInt(row.data[84], 10) : form.workforce.women.parttime2 = 0;
              row.data[71] ? form.workforce.women.strangers = parseInt(row.data[71], 10) : form.workforce.women.strangers = 0;
              row.data[70] ? form.workforce.women.swiss = parseInt(row.data[70], 10) : form.workforce.women.swiss = 0;

              let ret = await APIService.post("/companies/"+returnVal.data["id"]+"/forms/update", form);
              globalData.$data.$importStep += 1;
              if (ret.status == 201){
                globalData.$data.$importStep += 1;
              } else {
                console.debug("ERROR " + ret.status + " while updating form : " + ret.data);
                globalData.$data.$importStep += 1;
              }
            } else {
            console.debug("ERROR " + company.status + " while getting company from base : " + company.data);
            globalData.$data.$importStep += 1;
            }
          } else {
            console.debug("ERROR " + returnVal.status + " while registring company : " + returnVal.data);
            globalData.$data.$importStep += 1;
          }
          globalData.$data.$importProg = globalData.$data.$importProg + 1;
          globalData.$data.$importStep += 1;
        } catch {
          globalData.$data.$importProg = globalData.$data.$importProg + 1;
        }
      },
      complete: function() {
        console.debug("Importation task created");
      }
    });
  }
  getImportProgress() {
    if (globalData.$data.$importProg == globalData.$data.$importTotal && globalData.$data.$importTotal > 0){
      this.loadingDialog = false;
      this.refreshList();
      globalData.$data.$importProg = 0;
      globalData.$data.$importTotal = 0;
      globalData.$data.$importStep = 0;
    }
    return "Terminés : " + globalData.$data.$importProg + "/" + globalData.$data.$importTotal;
  }
  getImportPorcent() {
    let ret = "0";
    if (globalData.$data.$importTotal > 0){
      ret = parseFloat(""+(globalData.$data.$importStep / (5*globalData.$data.$importTotal))*100).toFixed(2) + "%";
    }
    return ret;
  }

  exportCSV() {
    const csv = Papa.unparse({
      fields: [
          "Raison sociale",
          "Forme juridique",
          "Personne de contact",
          "N° contribuable",
          "N° NOGA",
          "N° BUR",
          "Activités",
          "Sous-types d'activités",
          "Type établie",
          "Description",
          "Soumise à CCT",
          "Convention CCT",
          "Info par email",
          "Enseigne",
          "Début de l'activité",
          "Téléphone",
          "Email",
          "Fax",
          "Site internet",
          "Adresse",
          "P.A.",
          "Surf. des locaux",
          "Capital social",
          "Siège Sign",
          "Nbre d'employé(s) dans l'administration",
          "Nbre d'employé(s) de production",
          "Travailleurs hommes suisses",
          "Travailleurs hommes étrangers",
          "Travailleurs hommes temps complet",
          "Travailleurs hommes partiels >50%",
          "Travailleurs hommes partiels <50%",
          "Travailleurs femmes suisses",
          "Travailleurs femmes étrangers",
          "Travailleurs femmes temps complet",
          "Travailleurs femmes partiels >50%",
          "Travailleurs femmes partiels <50%",
          "Travailleurs permis B",
          "Travailleurs permis C",
          "Travailleurs permis L",
          "Travailleurs autres permis",
          "Date de la fin d'activité",
          "Cause de la fin d'activité",
          "Précisions sur la fin d'activité",
          "Contact d'urgence : Fonctions",
          "Contact d'urgence : Titre",
          "Contact d'urgence : Nom",
          "Contact d'urgence : Prénom",
          "Contact d'urgence : Adresse",
          "Contact d'urgence : Mobile",
          "Contact d'urgence : Téléphone",
          "Contact d'urgence : Email",
          "Contact d'urgence : Fax",
          "Contact d'urgence : Autre(s)",
          "RC : enregistré",
          "RC : numéro",
          "RC : date",
          "N° AVS",
          "N° alloc. familiale",
          "ID",
          "Status de validation",
          "Date de creation",
          "Date de mise a jour",
          "Notes"
        ],
      data: this.companies.map((elem: any)=>{return [
        elem.form.description.socialReason,
        elem.form.description.legalForm.value,
        elem.form.contact_full,
        elem.form.description.taxpayerNo,
        elem.form.description.NOGANo,
        elem.form.description.BURNo,
        elem.form.description.activity.listAllTypes(),
        elem.form.description.activity.listAllSubTypes(),
        elem.form.description.activity.listAllComTypes(),
        elem.form.description.activity.description,
        elem.form.description.cct.state,
        elem.form.description.cct.name,
        elem.form.description.newsletterSub,
        elem.form.description.enseigne,
        this.join(new Date(elem.form.municipalTerritory.activityStartDate), this.a, '/'),
        elem.form.municipalTerritory.phoneNumber,
        elem.form.municipalTerritory.emailAddress,
        elem.form.municipalTerritory.faxNumber,
        elem.form.municipalTerritory.websiteURL,
        elem.form.municipalTerritory.address,
        elem.form.municipalTerritory.forAddress,
        elem.form.municipalTerritory.premisesSurface,
        elem.form.municipalTerritory.shareCapital,
        elem.form.siege.sign,
        elem.form.workforce.administration,
        elem.form.workforce.production,
        elem.form.workforce.men.swiss,
        elem.form.workforce.men.strangers,
        elem.form.workforce.men.fulltime,
        elem.form.workforce.men.parttime,
        elem.form.workforce.men.parttime2,
        elem.form.workforce.women.swiss,
        elem.form.workforce.women.strangers,
        elem.form.workforce.women.fulltime,
        elem.form.workforce.women.parttime,
        elem.form.workforce.women.parttime2,
        elem.form.workforce.permits.B,
        elem.form.workforce.permits.C,
        elem.form.workforce.permits.L,
        elem.form.workforce.permits.other,
        this.join(new Date(elem.form.endOfActivity.date), this.a, '/'),
        elem.form.endOfActivity.cause.value,
        elem.form.endOfActivity.description,
        elem.form.contacts[0].listAllFunc(),
        elem.form.contacts[0].title,
        elem.form.contacts[0].last_name,
        elem.form.contacts[0].first_name,
        elem.form.contacts[0].address.street + " " + elem.form.contacts[0].address.zip + " " + elem.form.contacts[0].address.city ,
        elem.form.contacts[0].mobile,
        elem.form.contacts[0].telephone,
        elem.form.contacts[0].email,
        elem.form.contacts[0].fax,
        elem.form.contacts[0].other,
        elem.form.rc_registration.etat,
        elem.form.rc_registration.numero,
        this.join(new Date(elem.form.rc_registration.date), this.a, '/'),
        elem.form.AVSno,
        elem.form.familyAllowanceNo,
        elem._id,
        elem.form.validation_status,
        this.join(new Date(elem.created_at), this.a, '/'),
        this.join(new Date(elem.updated_at), this.a, '/'),
        elem.adminNotes
      ]})},
      {
        delimiter: ";"
      });
    this.forceFileDownload(csv);
  }
  forceFileDownload(response){
      const url = window.URL.createObjectURL(new Blob(["\ufeff", response],{type:"text/plain;charset=UTF-8"}))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'entreprise.csv') //or any other extension
      document.body.appendChild(link)
      link.click()
  }

  editEntreprise(entreprise: any) {
    this.$router.push('/administration/entreprises/'+entreprise._id);
  }

  editEntrepriseInLine(event: any){
    this.editEntreprise({...event.data})
  }

  validateEntreprise(entreprise: any) {
    APIService.get('/companies/'+entreprise._id).then((response: AxiosResponse) =>{
                    if(response.status == 200){
                        let company = Company.getCompanyFromList(response.data);
                        APIService.post("/companies/" + entreprise._id + "/forms/adminValidate", company.form).then(() => {
                          this.refreshList()
                        });
                    } else {
                        this.refreshList()
                    }
                });
  }
  refreshList() {
    //this.companies.refresh()
    this.$router.push('/').then(() => this.$router.push('administration/'));
  }

  registreComm = [];
  urgenceFonction = [];
  directeurFonction = [];
  chefFonction = [];

  //FILTER LIST
  catToFiltre = [];
  statToFiltre = [];
  //récupère liste de catégories
  getTaxList(taxKey: string){
    return (this.taxonomies(taxKey) && this.taxonomies(taxKey).terms) ?
    this.taxonomies(taxKey).terms.map((elem: any)=>{
                              return {
                            label : elem.label,
                            value : elem.value,
                        }
    }) : []
  }
  activNoActive = [{label: "Actives", value: 0},{label: "Inactives", value: 1}]

  fetchData(){
    return APIService.get('companiesCount').then((response: AxiosResponse) =>{
      this.totalComp = response.data
      this.fetchDataStep(0)
    });
  }
  fetchDataStep(i: any){
    APIService.get('/companiesshortadm/' + i).then((response: AxiosResponse) =>{
          if (response.data == "DONE" || this.companies.length != 0){
            console.debug("Successfully fetched companies ");
          } else {
            this.entreprises_quick = this.entreprises_quick.concat(Company.getCompaniesFromList(response.data));
            this.fetchDataStep(i+1)
            this.loading += 20/this.totalComp*100
          }
        });
  }

  mounted(){
    this.fetchData();
  }
}


