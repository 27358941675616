
import { Component, Vue } from "vue-property-decorator";
import FormulaireProfil from "components/Entreprise/FormulaireProfil.vue";
import { mixins } from "vue-class-component";
import TrsBasicView from "views/BasicView.vue";
import User from "models/User";
import authService from "services/auth.service";

@Component({
  components: {
    FormulaireProfil,
  },
})
export default class ProfileView extends mixins(TrsBasicView) {

  title = "Mon Profil";
  user: User|null = null;

  created(){
    this.user = authService.getUser();
  }

}
