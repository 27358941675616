
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TrsBasicView from 'views/BasicView.vue';

@Component 
export default class SentEmailsView extends mixins(TrsBasicView) {

  title = "Emails - Liste des courriels envoyés";
  
}
