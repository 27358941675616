/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Ressource
 * Purpose  : This file imports in one place all necessary
 *            prime components for the SPA to work
 *************************************************************/
/* eslint-disable */

import Vue from "vue";

import Menu from 'primevue/menu';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import FileUpload from 'primevue/fileupload';
import Toolbar from 'primevue/toolbar';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import Message from 'primevue/message';
import InputSwitch from 'primevue/inputswitch';
import ToggleButton from 'primevue/togglebutton';
import Chips from 'primevue/chips';
import MultiSelect from 'primevue/multiselect';
import SelectButton from 'primevue/selectbutton';
import Listbox from 'primevue/listbox';
import Tree from 'primevue/tree';
import OverlayPanel from 'primevue/overlaypanel';
import TieredMenu from 'primevue/tieredmenu';
import Avatar from 'primevue/avatar';
import Divider from 'primevue/divider';
import CardModule from 'primevue/card';
import ScrollPanel from 'primevue/scrollpanel';
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';
import Editor from 'primevue/editor';
import Toast from 'primevue/toast';
import Chart from 'primevue/chart';
import AutoComplete from 'primevue/autocomplete';



Vue.component('Menu', Menu);
Vue.component('InputText', InputText);
Vue.component('Password', Password);
Vue.component('Button', Button);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('FileUpload', FileUpload);
Vue.component('Toolbar', Toolbar);
Vue.component('Dialog', Dialog);
Vue.component('Panel', Panel);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('ColumnGroup', ColumnGroup);
Vue.component('Row', Row);
Vue.component('Dropdown', Dropdown);
Vue.component('Textarea', Textarea);
Vue.component('Checkbox', Checkbox);
Vue.component('RadioButton', RadioButton);
Vue.component('Calendar', Calendar);
Vue.component('InputNumber', InputNumber);
Vue.component('InputMask', InputMask);
Vue.component('Message', Message);
Vue.component('InputSwitch', InputSwitch);
Vue.component('ToggleButton', ToggleButton);
Vue.component('Chips', Chips);
Vue.component('MultiSelect', MultiSelect);
Vue.component('SelectButton', SelectButton);
Vue.component('Listbox', Listbox);
Vue.component('Tree', Tree);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('TieredMenu', TieredMenu);
Vue.component('Avatar', Avatar)
Vue.component('Divider', Divider)
Vue.component('Card', CardModule)
Vue.component('ScrollPanel', ScrollPanel)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('Editor', Editor)
Vue.component('Toast', Toast)
Vue.component('Chart', Chart)
Vue.component('ProgressBar', ProgressBar)
Vue.component('AutoComplete', AutoComplete)
