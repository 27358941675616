import JWT from "jwt-decode";
import AuthService from "services/auth.service";

export default class AuthGuard {
    public static Company(to: any, from: any, next: any) {
        AuthService.refreshUser();
        if (AuthService.userCan('company')) {
            next();
        }
        else {
            next('/login');
        }
    }
    public static Admin(to: any, from: any, next: any) {
        if(AuthService.userCan('admin')){
            next();
        }
        else if (AuthService.isAuthenticated()) {
            //RFo : The user is logged in but is not an admin
            next('/');
        }
        else {
            next('/login');
        }
    }
}
