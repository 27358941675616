







import { Component, Vue } from "vue-property-decorator";
import ListeEmploye from "components/Entreprise/ListeEmploye.vue";

@Component({
  components: {
    ListeEmploye,
  },
})
export default class Employes extends Vue {}
