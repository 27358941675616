/* eslint-disable */

export default class Mail {
    _id?                : string;

    piecesJointes?      : File[];
    piecesJointesPath?   : string[];
    from                : string;
    to                  : string[];
    cc?                 : string[];
    bcc?                : string[];
    object              : string;
    content             : string;

    sent_at?            : string;

    public constructor(object?: any){
        object                  = object || {}

        this._id                = object._id;
        this.piecesJointes      = object.piecesJointes || [];
        this.piecesJointesPath   = object.piecesJointesPath || [];
        this.from               = object.from || "";
        this.to                 = object.to || "";
        this.cc                 = object.cc || "";
        this.bcc                = object.bcc || "";
        this.object             = object.object || "";
        this.content            = object.content || "";

        this.sent_at            = object.created_at;
    }

    public static getMailFromList(objects: any): Mail[]{
        if(objects && objects.length){
            return [...objects.map( (x:any) => new Mail(x) )]
        }
        return []
    }
}

export class PiecesJointes {
    content   : File

    public constructor(object?: any){
        object       = object || {}

        this.content    = object.content || ""
    }
}
