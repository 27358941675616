import { AxiosResponse } from 'axios';
import MenuItem from 'models/MenuItem';
import { Store, Module} from 'vuex'
import APIService from './api.service';

interface NavServiceIF {
    sidebar_items: MenuItem[];
    topbar_items: MenuItem[];
    current: {
        title: string;
    };
}

export default class NavigationService {

    private store: Store<any>;
    private storeModule: Module<any, any> =  {
        namespaced : true,
        state: () =>({
            sidebar_items   : [],
            topbar_items    : [],
            current         : {
                title   : ""
            }
        }),
        mutations: {
            setTopBarItems(state: NavServiceIF, items: MenuItem[]){
                state.topbar_items = MenuItem.getItemsFromList(items) || [];
            },
            setSidebarItems(state: NavServiceIF, items: MenuItem[]){
                state.sidebar_items = MenuItem.getItemsFromList(items) || [];
            },
            setCurrentPageTitle(state: NavServiceIF, title: string){
                state.current.title = title || "";
            }
        },
        actions: {},
        getters: {
            getTopbarItems: (state: NavServiceIF) => {
                return state.topbar_items
            },
            getSidebarItems: (state: NavServiceIF) => {
                return state.sidebar_items
            },
            getCurrentPage: (state: NavServiceIF) => {
                return state.current
            },
        }
    }


    private constructor(store: Store<any>){
        store.registerModule('navigation', this.storeModule);
        this.store = store;
        this.store.commit("navigation/setSidebarItems", this.getInitialSidebarItems())
    }

    static _instance: NavigationService;

    public static init(store: Store<any>){
        if(!this._instance)
            this._instance = new NavigationService(store);
        return this._instance;
    }

    public static get(store?: Store<any>): NavigationService{
        if(!store && !this._instance){
            console.error("Trying to access navigation service's instance without init first and passing store !")
            throw "Trying to access navigation service's instance without init first and passing store !"
        } else if (this._instance){
            return this._instance;
        } else {
            return this.init(store!)
        }
    }

    public refresh(){
        return this.store.commit("navigation/setSidebarItems", this.getInitialSidebarItems())
    }

    public getSidebarItems(){
        return this.store.getters['navigation/getSidebarItems']
    }

    public getTopbarItems(){
        return this.store.getters['navigation/getTopbarItems']
    }

    public getCurrentPage(){
        return this.store.getters['navigation/getCurrentPage']
    }

    public setCurrentPageTitle(title: string){
        return this.store.commit('navigation/setCurrentPageTitle', title)
    }

    private getInitialSidebarItems(){
        return [
            {
                label   : "Accueil",
                icon    : "pi pi-fw pi-home",
                to      : "/",
                userCan : "public"
            },
            {
                label   : "Connexion / Inscription",
                icon    : "pi pi-fw pi-sign-in",
                to      : "/login",
                role    : "public"
             },
            {
                label   : "Entreprise",
                icon    : "pi pi-fw pi-pencil",
                to      : "/entreprise",
                role    : "company"
            },
            /*
            {
              label: "Employés",
              icon: "pi pi-fw pi-pencil",
              to: "/entreprise/employes",
            },*/
            {
              label: "Administration",
              icon: "pi pi-fw pi-table",
              to: "/administration",
              userCan : "admin"
            },
            {
              label: "Historique",
              userCan : "admin",
              icon: "pi pi-fw pi-refresh",
              to: "/administration/mise-a-jour/historique",
               /* {
                  label: "Historique",
                  icon: "pi pi-fw pi-pencil",
                  to: "/administration/mise-a-jour/historique",
                  userCan : "admin",
                },
                {
                  label: "Validation",
                  icon: "pi pi-fw pi-pencil",
                  to: "/administration/mise-a-jour/validation",
                  userCan : "admin",
                },
              ],*/
            },
            {
              label: "Courriel",
              userCan : "admin",
              icon: "pi pi-fw pi-envelope",
              items: [
                {
                    label: "Nouveau",
                    icon: "pi pi-fw pi-pencil",
                    to: "/administration/emails/nouveau",
                    userCan : "admin",
                },
                {
                  label: "Envoyés",
                  icon: "pi pi-fw pi-pencil",
                  to: "/administration/emails/envoyes",
                  userCan : "admin",
                },
              ],
            },
            {
                label: "Statistique",
                userCan: "admin",
                icon: "pi pi-fw pi-chart-bar",
                to: "/administration/statistique",
            }
          ];
    }
}
