var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.companies.length == 0 && _vm.entreprises_quick.length == 0)?_c('span',[_c('ProgressSpinner',{staticStyle:{"height":"30px"}}),_c('h5',[_vm._v("Entreprise en cours de chargement...")])],1):_vm._e(),_c('div',{staticClass:"p-shadow-1 table"},[(_vm.companies.length == 0 && _vm.entreprises_quick.length != 0)?_c('ProgressBar',{attrs:{"value":_vm.loading*3,"showValue":false}}):_vm._e(),(_vm.companies.length != 0)?_c('DataTable',{ref:"dt",staticClass:"admin-val-table",attrs:{"value":_vm.companies,"data-key":"id","paginator":true,"rows":10,"filters":_vm.filters,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":[10, 20, 50],"currentPageReportTemplate":"Entreprises {first} à {last} sur {totalRecords}","selectionMode":"single"},on:{"row-select":_vm.editEntrepriseInLine},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header recherche"},[_c('span',{staticClass:"p-input-icon-left"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{attrs:{"placeholder":"Recherche..."},model:{value:(_vm.filters['global']),callback:function ($$v) {_vm.$set(_vm.filters, 'global', $$v)},expression:"filters['global']"}})],1)])]},proxy:true}],null,false,1745546835)},[_c('Column',{attrs:{"field":"form.description.socialReason","header":"Raison sociale","sortable":""}}),_c('Column',{attrs:{"field":"created_at","header":"Date de création","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.createdDate(data))+" ")]}}],null,false,280413055)}),_c('Column',{attrs:{"field":"updated_at","header":"Date de modification","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.modifDate(data))+" ")]}}],null,false,82829494)}),_c('Column',{attrs:{"field":"form.contact_full","header":"Personne de contact","sortable":""}}),_c('Column',{attrs:{"field":"form.validation_status","header":"Status","sortable":""}}),_c('Column',{attrs:{"exportable":false,"headerStyle":"width:150px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('Button',{staticClass:"p-button-rounded p-button-help p-mr-2 button-col",attrs:{"label":"Versions","icon":"pi pi-align-justify","placeholder":"Editer"},on:{"click":function($event){return _vm.editEntreprise(slotProps.data)}}})]}}],null,false,1625496161)})],1):_vm._e(),(_vm.companies.length == 0 && _vm.entreprises_quick.length != 0)?_c('DataTable',{ref:"dt",staticClass:"admin-val-table",attrs:{"value":_vm.entreprises_quick,"data-key":"id","paginator":true,"rows":10,"filters":_vm.filters,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":[10, 20, 50],"currentPageReportTemplate":"Entreprises {first} à {last} sur {totalRecords}","selectionMode":"single"},on:{"row-select":_vm.editEntrepriseInLine},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header recherche"},[_c('span',{staticClass:"p-input-icon-left"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{attrs:{"placeholder":"Recherche..."},model:{value:(_vm.filters['global']),callback:function ($$v) {_vm.$set(_vm.filters, 'global', $$v)},expression:"filters['global']"}})],1)])]},proxy:true}],null,false,1745546835)},[_c('Column',{attrs:{"field":"form.description.socialReason","header":"Raison sociale","sortable":""}}),_c('Column',{attrs:{"field":"created_at","header":"Date de création","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.createdDate(data))+" ")]}}],null,false,280413055)}),_c('Column',{attrs:{"field":"updated_at","header":"Date de modification","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.modifDate(data))+" ")]}}],null,false,82829494)}),_c('Column',{attrs:{"field":"form.contact_full","header":"Personne de contact","sortable":""}}),_c('Column',{attrs:{"field":"form.validation_status","header":"Status","sortable":""}}),_c('Column',{attrs:{"exportable":false,"headerStyle":"width:150px"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('Button',{staticClass:"p-button-rounded p-button-help p-mr-2 button-col",attrs:{"label":"Versions","icon":"pi pi-align-justify","placeholder":"Editer"},on:{"click":function($event){return _vm.editEntreprise(slotProps.data)}}})]}}],null,false,1625496161)})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }