
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company legal infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import User from "models/User";
import UserService from "services/user.service"
import authService from "services/auth.service";
import APIService from "services/api.service";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters('users', {
      userGetter: 'getOneByKey'
    })
  }
})
export default class UserFormComponent extends Vue {

  //@VModel({ type: User }) user!: User
  @Prop({ default: false }) readonly userIsMe!: boolean

  userGetter!: any;
  user = new User();
  touched = false
  resetPassword = false;

  mediumRegex = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})' // eslint-disable-line
  strongRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})' // eslint-disable-line

  doubleAuthLink = "";
  doubleAuthDialog = false;

  loading = true;

  created(){
    APIService.get("/me").then((res: any) => {
      this.user = res.data;
      UserService.get().refresh().then((res: any)=>{
      this.user = this.userGetter(this.user._id);
      this.loading = false;
    })
    });
  }

  onTouched($event: any){
    this.touched = true;
  }

  sendSave(){
    if(this.checkFormValidity()){
      if(!this.resetPassword || !this.user.password || (this.user.password?.length < 4)){
        delete(this.user.password);
      }
      delete(this.user.passwordConfirmation);

      APIService.post("/users/"+this.user._id+"/update", this.user).then((res: any)=> {
        this.user.password = this.user.passwordConfirmation = "";
        this.touched = false;
        this.resetPassword = false;

        if(this.userIsMe){
          authService.refreshUser();
        }
      }).catch((err: any)=> {
        console.error("Error while updating user", err)
      })

    }
  }

  passwordConfValidity(){
    return this.user.password == this.user.passwordConfirmation;
  }

  checkFormValidity(){
    return this.passwordConfValidity();
  }

  activateDoubleAuth(){
    APIService.post("/users/"+this.user._id+"/gensec", this.user).then((res: any)=> {
        if(res.status == 200){
          this.doubleAuthLink = res.data.url;
          this.doubleAuthDialog = true;
        }
    }).catch((err: any)=> {
        console.error("Error while pairing double auth", err)
    })
  }

  deactivateDoubleAuth() {
    this.user.two_factor_status = false;
    this.sendSave();
  }
  confirmJumelage(){
    this.doubleAuthDialog = false;
    this.user.two_factor_status = true;
    if(this.userIsMe){
      authService.refreshUser();
    }
  }

}
