/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Model
 * Purpose  : Typescript Model for taxonomies fetched from DB
 *************************************************************/

import TaxonomiesService from "services/taxonomies.service";

/* eslint-disable */

export default class Taxonomy {
    _id?            : string;
    key             : string;
    display_name    : string;
    terms           : TaxonomyTerm[]

    created_at?     : string
    updated_at?     : string
        
    public constructor(object?: any){
        object                  = object || {}
        this.key                = object.key || '';
        this.display_name       = object.display_name || '';
        
        this._id                = object._id;
        this.created_at         = object.created_at;
        this.updated_at         = object.updated_at;
        
        
        this.terms = TaxonomyTerm.getTermsFromList(object.terms);
    }

    public static getTaxonomiesFromList(objects: any): Taxonomy[]{
        if(objects && objects.length){
            return objects.map( (x:any) => new Taxonomy(x) )
        }
        return []
    }
}

export class TaxonomyTerm {
    label           : string;
    value           : string;
    children?       : TaxonomyTerm[]
        
    public constructor(object?: any){
        object                  = object || {}
        this.label              = object.label || '';
        this.value              = object.value || '';
        
        if(object.children && object.children.length)
            this.children = TaxonomyTerm.getTermsFromList(object.children)
    }

    public static getTermsFromList(objects: any): TaxonomyTerm[]{
        if(objects && objects.length){
            return objects.map( (x:any) => new TaxonomyTerm(x) )
        }
        return []
    }
}