
import { Component, Vue } from "vue-property-decorator";
import { mapGetters} from 'vuex';
import Company from "models/Company";
import { forEach } from "@tiptap/core/dist/packages/core/src/commands/forEach";
import APIService from "services/api.service";
import { AxiosResponse } from 'axios';

@Component({
  computed: {
    ...mapGetters('companies', {
      companies: 'getAll'
    }),
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    })
  }
})
export default class Bar extends Vue {
  companies!: any;
  taxonomies!: any;
  date = new Date();
  year = this.date.getFullYear();
  month = this.date.getMonth();
  day = this.date.getDate();
  monthText = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

  dataYear = null;
  dataMonth = null;
  dataDay = null;
  dataTotalEntr = 0;
  dataSurface = 0;
  dataCapit = 0;
  dataConv = 0;
  dataReg = 0;
  dataYearCount = [0,0,0,0,0,0,0];
  dataMonthCount = [0,0,0,0,0,0,0];
  dataDayCount = [0,0,0,0,0,0,0];
  dataStatusCount = [0,0,0];
  dataCatCount = [];
  dataEmpCount = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
  dataStatus = null;
  dataCat = null;
  dataEmp = null;
  dataHF = null;
  dataSwiss = null;
  dataHFSwiss = null;
  dataTime = null;
  dataTimeH = null;
  dataTimeF = null;
  dataPermis = null;

  showActivity = false;
  showEmpl = false;
  showStat = false;
  showFilters = false;

  totalComp = 1;
  entreprises_quick = [];
  loading = 0;
  filter = {
    cat: null,
    active: null,
  };
  catList = [];

  catData = {
      labels: ['cat1', 'cat2', 'cat3', 'cat4', 'cat5'],
      datasets: [
          {
              label: 'Entreprises enregistrées',
              data: [626, 59, 80, 23, 323],
              backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
              hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
          },
      ]
  };

  created () {
    this.getData(this.filter);
    this.getvalidationData();
    this.getCatData();
    this.getNbrCompDataYear();
    this.getNbrCompDataMonth();
    this.getNbrCompDataDay();
    this.getDataHF();
    this.getDataSwiss();
    this.getDataHFSwiss();
    this.getDataTime();
    this.getDataTimeH();
    this.getDataTimeF();
    this.getDataPermis();
  }
  beforeUpdate () {
    this.resetData();
    this.getData(this.filter);
    this.getvalidationData();
    this.getCatData();
    this.getNbrCompDataYear();
    this.getNbrCompDataMonth();
    this.getNbrCompDataDay();
    this.getDataHF();
    this.getDataSwiss();
    this.getDataHFSwiss();
    this.getDataTime();
    this.getDataTimeH();
    this.getDataTimeF();
    this.getDataPermis();
  }

  resetData(){
    this.dataYear = null;
    this.dataMonth = null;
    this.dataDay = null;
    this.dataTotalEntr = 0;
    this.dataSurface = 0;
    this.dataCapit = 0;
    this.dataConv = 0;
    this.dataReg = 0;
    this.dataYearCount = [0,0,0,0,0,0,0];
    this.dataMonthCount = [0,0,0,0,0,0,0];
    this.dataDayCount = [0,0,0,0,0,0,0];
    this.dataStatusCount = [0,0,0];
    this.dataCatCount = [];
    this.dataEmpCount = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.dataStatus = null;
    this.dataCat = null;
    this.dataEmp = null;
    this.dataHF = null;
    this.dataSwiss = null;
    this.dataHFSwiss = null;
    this.dataTime = null;
    this.dataTimeH = null;
    this.dataTimeF = null;
    this.dataPermis = null;
  }

  getFilterChoices(){
    return this.catList.map((elem: any)=>{
                              return {
                            label : elem,
                            value : elem,
                        }});
  }

  getData(filter: any){
    this.companies.forEach(element => {
      if(element.form && element.form.description && element.form.description.activity && element.form.description.activity.types){
        element.form.description.activity.types.forEach(activ => {
          if(!this.catList.includes(activ.value)){
                this.catList.push(activ.value);
              }
        });
      }
      let filter_check = ((filter.cat == null) || (filter.cat.length == 0)) && ((filter.active == null) || (!filter.active))
      if(filter.cat && filter.cat.length != 0 && element.form && element.form.description && element.form.description.activity && element.form.description.activity.types){
        element.form.description.activity.types.forEach(activ => {
          filter.cat.forEach(fil => {
            if (activ.value == fil.value){
              filter_check = true;
            }
          });
        });
      }
      if(filter.active && element.form && element.form.endOfActivity && !(element.form.endOfActivity.cause.label)){
        filter_check = true;
      }

      if (filter_check){
        //total des entreprises :
        this.dataTotalEntr += 1;
        //surface des locaux :
        if (element.form && element.form.municipalTerritory && element.form.municipalTerritory.premisesSurface){
          this.dataSurface += element.form.municipalTerritory.premisesSurface;
        }
        //capital social :
        if (element.form && element.form.municipalTerritory && element.form.municipalTerritory.shareCapital){
          this.dataCapit += element.form.municipalTerritory.shareCapital;
        }
        //convention collectives :
        if (element.form && element.form.description && element.form.description.cct && element.form.description.cct.state){
          this.dataConv += 1;
        }
        //registre du commerce :
        if (element.form && element.form.rc_registration && element.form.rc_registration.etat){
          this.dataReg += 1;
        }
        //employés :
        if(element.form && element.form.workforce){
          if(element.form.workforce.administration){
            this.dataEmpCount[0] += element.form.workforce.administration
          }
          if(element.form.workforce.production){
            this.dataEmpCount[1] += element.form.workforce.production
          }
          if(element.form.workforce.men.swiss){
            this.dataEmpCount[2] += element.form.workforce.men.swiss
          }
          if(element.form.workforce.men.strangers){
            this.dataEmpCount[3] += element.form.workforce.men.strangers
          }
          if(element.form.workforce.women.swiss){
            this.dataEmpCount[4] += element.form.workforce.women.swiss
          }
          if(element.form.workforce.women.strangers){
            this.dataEmpCount[5] += element.form.workforce.women.strangers
          }
          if(element.form.workforce.men.fulltime){
            this.dataEmpCount[6] += element.form.workforce.men.fulltime
          }
          if(element.form.workforce.women.fulltime){
            this.dataEmpCount[7] += element.form.workforce.women.fulltime
          }
          if(element.form.workforce.men.parttime){
            this.dataEmpCount[8] += element.form.workforce.men.parttime
          }
          if(element.form.workforce.women.parttime){
            this.dataEmpCount[9] += element.form.workforce.women.parttime
          }
          if(element.form.workforce.men.parttime2){
            this.dataEmpCount[10] += element.form.workforce.men.parttime2
          }
          if(element.form.workforce.women.parttime2){
            this.dataEmpCount[11] += element.form.workforce.women.parttime2
          }
          if(element.form.workforce.permits.B){
            this.dataEmpCount[12] += element.form.workforce.permits.B
          }
          if(element.form.workforce.permits.C){
            this.dataEmpCount[13] += element.form.workforce.permits.C
          }
          if(element.form.workforce.permits.L){
            this.dataEmpCount[14] += element.form.workforce.permits.L
          }
          if(element.form.workforce.permits.other){
            this.dataEmpCount[15] += element.form.workforce.permits.other
          }
        }
        //Status de validation :
        if(element.form && element.form.validation_status){
          switch (element.form.validation_status){
            case "pending_validation":
              this.dataStatusCount[0] += 1;
              break;
            case "validated":
              this.dataStatusCount[1] += 1;
              break;
            case "created":
              this.dataStatusCount[2] += 1;
              break;
          }
        }
        //Catégories :
        if(element.form && element.form.description && element.form.description.activity && element.form.description.activity.types){
          element.form.description.activity.types.forEach(activ => {
            if (!this.dataCatCount[activ.value]){
              this.dataCatCount[activ.value] = 1;
            } else {
              this.dataCatCount[activ.value] += 1;
            }
          });
        }
        //date de création
        for(let i = 0; i <7; i++){
          if (element.created_at){
            let createdDate = new Date(element.created_at)
            //années
            if (createdDate.getFullYear() == this.year-i){
              this.dataYearCount[i] += 1;
            }
            //mois
            if (this.month-i < 0){
              if(createdDate.getMonth() == this.month-i+12 && createdDate.getFullYear() == this.year-1){
                this.dataMonthCount[i] += 1;
              }
            } else {
              if(createdDate.getMonth() == this.month-i && createdDate.getFullYear() == this.year){
                this.dataMonthCount[i] += 1;
              }
            }
            //jours
            if(createdDate.getDate() == this.day - i){
              this.dataDayCount[i] += 1;
            }
          }
        }
      }
    });
  }
  getvalidationData() {
    this.dataStatus = {
      labels: ['En attente', 'Validé', 'Brouillon'],
      datasets: [
          {
              label: 'Entreprises enregistrées',
              data: [this.dataStatusCount[0], this.dataStatusCount[1], this.dataStatusCount[2]],
              backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
              hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
          },
      ]
    };
  }
  getCatData() {
    let dataCatLabel = []
    let dataCatData = []
    for(let elem in this.dataCatCount){
      dataCatLabel.push(elem);
      dataCatData.push(this.dataCatCount[elem]);
    }
    this.dataCat = {
      labels: dataCatLabel,
      datasets: [
          {
              label: 'Entreprises',
              data: dataCatData,
              backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
              hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
          },
      ]
    };
  }
  getNbrCompDataYear() {
    this.dataYear = {
        labels: [this.year-6, this.year-5, this.year-4, this.year-3, this.year-2, this.year-1 , this.year],
        datasets: [
            {
                label: 'Entreprises enregistrées',
                backgroundColor: '#42A5F5',
                data: [this.dataYearCount[6],
                      this.dataYearCount[5],
                      this.dataYearCount[4],
                      this.dataYearCount[3],
                      this.dataYearCount[2],
                      this.dataYearCount[1],
                      this.dataYearCount[0]]
            }
        ]
    };
  }
  getNbrCompDataMonth() {
    this.dataMonth = {
        labels: [this.monthText[(this.month-6+12) % 12], this.monthText[(this.month-5+12) % 12], this.monthText[(this.month-4+12) % 12], this.monthText[(this.month-3+12) % 12], this.monthText[(this.month-2+12) % 12] , this.monthText[(this.month-1+12) % 12], this.monthText[this.month]],
        datasets: [
            {
                label: 'Entreprises enregistrées',
                backgroundColor: '#42A5F5',
                data: [this.dataMonthCount[6],
                      this.dataMonthCount[5],
                      this.dataMonthCount[4],
                      this.dataMonthCount[3],
                      this.dataMonthCount[2],
                      this.dataMonthCount[1],
                      this.dataMonthCount[0]]
            }
        ]
    };
  }
  getNbrCompDataDay() {
    this.dataDay = {
        labels: [this.day-6, this.day-5, this.day-4, this.day-3, this.day-2, this.day-1 , this.day],
        datasets: [
            {
                label: 'Entreprises enregistrées',
                backgroundColor: '#42A5F5',
                data: [this.dataDayCount[6],
                      this.dataDayCount[5],
                      this.dataDayCount[4],
                      this.dataDayCount[3],
                      this.dataDayCount[2],
                      this.dataDayCount[1],
                      this.dataDayCount[0]]
            }
        ]
    };
  }
  getDataHF() {
    this.dataHF = {
        labels: ["Hommes", "Femmes"],
        datasets: [
            {
                label: 'Travailleurs',
                backgroundColor: ["#42A5F5","#BC60AA","#FFA726"],
                hoverBackgroundColor: ["#64B5F6","#C677B6","#FFB74D"],
                data: [this.dataEmpCount[2] + this.dataEmpCount[3],
                      this.dataEmpCount[4] + this.dataEmpCount[5]]
            }
        ]
    };
  }
  getDataSwiss() {
    this.dataSwiss = {
        labels: ["Suisses", "Etrangers"],
        datasets: [
            {
                label: 'Travailleurs',
                backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
                hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"],
                data: [this.dataEmpCount[2] + this.dataEmpCount[4],
                      this.dataEmpCount[3] + this.dataEmpCount[5]]
            }
        ]
    };
  }
  getDataHFSwiss(){
    this.dataHFSwiss = {
        labels: ["Hommes Suisses", "Hommes Etrangers", "Femmes Suisses", "Femmes Etrangers"],
        datasets: [
            {
                label: 'Travailleurs',
                backgroundColor: ["#42A5F5","#3FA2B5","#BC60AA", "#CB82BD"],
                hoverBackgroundColor: ["#64B5F6","#57B2C4","#C677B6", "#D293C5"],
                data: [this.dataEmpCount[2], this.dataEmpCount[3],
                      this.dataEmpCount[4], this.dataEmpCount[5]]
            }
        ]
    };
  }
  getDataTime() {
    this.dataTime = {
        labels: ["Plein temps", "+ 50%", "- 50%"],
        datasets: [
            {
                label: 'Travailleurs',
                backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
                hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"],
                data: [this.dataEmpCount[6] + this.dataEmpCount[7],
                      this.dataEmpCount[8] + this.dataEmpCount[9],
                      this.dataEmpCount[10] + this.dataEmpCount[11]]
            }
        ]
    };
  }
  getDataTimeH(){
    this.dataTimeH = {
        labels: ["Plein temps", "+ 50%", "- 50%"],
        datasets: [
            {
                label: 'Travailleurs',
                backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
                hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"],
                data: [this.dataEmpCount[6],
                      this.dataEmpCount[8],
                      this.dataEmpCount[10]]
            }
        ]
    };
  }
  getDataTimeF(){
    this.dataTimeF = {
        labels: ["Plein temps", "+ 50%", "- 50%"],
        datasets: [
            {
                label: 'Travailleurs',
                backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
                hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"],
                data: [this.dataEmpCount[7],
                      this.dataEmpCount[9],
                      this.dataEmpCount[11]]
            }
        ]
    };
  }
  getDataPermis(){
    this.dataPermis = {
        labels: ["B", "C", "L", "Autres"],
        datasets: [
            {
                label: 'Travailleurs',
                backgroundColor: ["#42A5F5","#66BB6A","#FFA726", "#CB83BD"],
                hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D", "#D499C9"],
                data: [this.dataEmpCount[12],
                      this.dataEmpCount[13],
                      this.dataEmpCount[14],
                      this.dataEmpCount[15]]
            }
        ]
    };
  }
  fetchData(){
    return APIService.get('companiesCount').then((response: AxiosResponse) =>{
      this.totalComp = response.data
      this.fetchDataStep(0)
    });
  }
  fetchDataStep(i: any){
    APIService.get('/companiesshortadm/' + i).then((response: AxiosResponse) =>{
          if (response.data == "DONE" || this.companies.length != 0){
            console.debug("Successfully fetched companies ");
          } else {
            this.entreprises_quick = this.entreprises_quick.concat(Company.getCompaniesFromList(response.data));
            this.fetchDataStep(i+1)
            this.loading += 20/this.totalComp*100
          }
        });
  }

  mounted(){
    this.fetchData();
  }
}
