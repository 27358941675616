
import { Component, Vue } from "vue-property-decorator";
import Mail from 'models/Mail';
import { mapGetters} from 'vuex';

@Component({
  computed: {
    ...mapGetters('mails', {
      mails: 'getAll',
    }),
  }
})
export default class EmailList extends Vue {
  mails!: any;
  mail = new Mail();
  showDialog = false;
  filters = {};


  showMail(mail: any){
    this.mail = new Mail({...mail.data});
    this.showDialog = true;
  }
  refreshList() {
    this.$router.push('/').then(() => this.$router.push('/administration/emails/envoyes'));
  }

      //date utility
  join(t, a, s) {
   function format(m) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
   }
   return a.map(format).join(s);
  }
  a = [{day: 'numeric'}, {month: 'numeric'}, {year: 'numeric'}];

  createdDate(elem: any){
    return this.join(new Date(elem.sent_at), this.a, '/');
  }
  correctDisplay(elem: any){
    let arr = "";
    for(let e in elem){
      arr += elem[e] + ", "
    }
    arr = arr.slice(0, arr.length-2)
    return arr
  }

}
