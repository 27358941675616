
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company legal infos and
 *            is used to be validated by the Commune
 *************************************************************/

import { CompanyAssociate } from "models/CompanyForm";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import CompanyForm from "models/CompanyForm"

@Component
export default class CompanyFormFormComponent extends Vue {

  @VModel({ type: CompanyForm }) companyForm!: CompanyForm
  touched = false
  needsDirector = true;
  needsChief = true;

  @Prop({
    default: true
  })
  showButtons!: boolean;

  /* ----- v-model ----- */
  @Prop()
  value!: CompanyForm;

  @Watch("value", { immediate: true, deep: true })
  onInstanceChange(value: any) {
      this.checkContacts();
  }

  /* ------------------- */

  constructor() {
    super();
  }

  onTouched($event: any){
    this.touched = true;
    this.checkContacts();

    this.$emit("touched", this.touched);
    this.$emit("change", this.companyForm)
  }

  sendCancel(){
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave(){
    this.$emit("input", this.companyForm);
    this.$emit("save", this.companyForm);
    this.touched = false;
  }

  addAssociate(){
    this.companyForm.associates.push(new CompanyAssociate())
    this.onTouched(true);
  }

  removeAssociate(index: number){
    this.companyForm.associates.splice(index, 1);
    this.onTouched(true);
  }

  checkContacts(){
    if(this.companyForm){
      if(this.needsDirector && this.companyForm.firstContactIsDirector()) {
        this.companyForm.removeDirector();
      }

      if(this.needsChief && this.companyForm.firstContactIsChief()) {
        this.companyForm.removeChief();
      }
      this.needsDirector = !this.companyForm.firstContactIsDirector();
      this.needsChief = !this.companyForm.firstContactIsChief();
    }
  }
}
