
import { Component, Vue } from "vue-property-decorator";
import ListeHistorique from "components/Administration/ListeHistorique.vue";
import { mixins } from "vue-class-component";
import TrsBasicView from "views/BasicView.vue";

@Component({
  components: {
    ListeHistorique,
  },
})
export default class Historique extends mixins(TrsBasicView) {

  title = "Historique des mises à jour";

}
