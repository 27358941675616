/* eslint-disable */

export default class MailTemplate {
    _id?                : string;
    name                : string;
    content             : string;

    public constructor(object?: any){
        object                  = object || {}

        this._id                = object._id;
        this.name               = object.name || "";
        this.content            = object.content || "";
    }

    public static getMailTemplateFromList(objects: any): MailTemplate[]{
        if(objects && objects.length){
            return [...objects.map( (x:any) => new MailTemplate(x) )]
        }
        return []
    }
}
