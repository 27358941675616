/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Ressource
 * Purpose  : This file imports in one place all the components
 *            created by Tris for the SPA to work
 *************************************************************/
/* eslint-disable */


import Vue from "vue";

import CompanyDescriptionFormComponent from "components/CompanyFormForm/form-parts/CompanyDescriptionForm.vue"
import CompanyTerritoryFormComponent from "components/CompanyFormForm/form-parts/CompanyTerritoryForm.vue"
import CompanyWorkforceFormComponent from "components/CompanyFormForm/form-parts/CompanyWorkforceForm.vue"
import CompanyEndActivityFormComponent from "components/CompanyFormForm/form-parts/CompanyEndActivityForm.vue"
import CompanyRCFormComponent from "components/CompanyFormForm/form-parts/CompanyRCForm.vue"
import CompanyContactInfoFormComponent from "components/CompanyFormForm/form-parts/CompanyContactInfoForm.vue"
import CompanyAssociateInfoFormComponent from "components/CompanyFormForm/form-parts/CompanyAssociateInfoForm.vue"
import CompanySiegeFormComponent from "components/CompanyFormForm/form-parts/CompanySiegeForm.vue"
import CompanyFormFormComponent from "components/CompanyFormForm/CompanyFormForm.vue"

import SidebarComponent from "components/menus/Sidebar.vue"
import SidebarMenuItem from "components/menus/SidebarMenuItem.vue"
import TopbarComponent from "components/menus/Topbar.vue"

import CompanyPublicList from 'components/CompanyPublicList.vue';
import EmailForm from 'components/Administration/Email/EmailForm.vue';
import EmailList from 'components/Administration/Email/EmailList.vue';
import UserFormComponent from 'components/Administration/UserForm.vue';

import Bar from 'components/Administration/Stats/Bar.vue';

Vue.component('CompanyDescriptionForm', CompanyDescriptionFormComponent);
Vue.component('CompanyTerritoryForm', CompanyTerritoryFormComponent);
Vue.component('CompanyWorkforceForm', CompanyWorkforceFormComponent);
Vue.component('CompanyEndActivityForm', CompanyEndActivityFormComponent);
Vue.component('CompanyRCForm', CompanyRCFormComponent);
Vue.component('CompanyContact', CompanyContactInfoFormComponent);
Vue.component('CompanyAssociate', CompanyAssociateInfoFormComponent);
Vue.component('CompanySiegeForm', CompanySiegeFormComponent);
Vue.component('CompanyFormForm', CompanyFormFormComponent);
Vue.component('trs-sidebar', SidebarComponent);
Vue.component('trs-sidebar-item', SidebarMenuItem);
Vue.component('trs-topbar', TopbarComponent);
Vue.component('CompanyPublicList', CompanyPublicList);
Vue.component('EmailForm', EmailForm);
Vue.component('EmailList', EmailList);
Vue.component('trs-userform', UserFormComponent);
Vue.component('Bar', Bar);


//Vue.component('Tiptap', Tiptap);




