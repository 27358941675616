/* eslint-disable */
import { ContactInformation } from "./common";
import { TaxonomyTerm } from "./Taxonomy";

export default class CompanyForm {
    _id?            : string;
    _company_id?    : string;

    contact_full?   : string;

    description?         : CompanyDescription;
    municipalTerritory?  : CompanyMunicipalTerritory;
    siege?               : CompanySiege
    workforce?           : CompanyWorkforce;
    endOfActivity?       : CompanyEndofActivity;
    contacts?            : CompanyContactInformation[];
    rc_registration?     : CompanyRCRegistration;
    associates?          : CompanyAssociate[]
    AVSno?               : string
    familyAllowanceNo?   : string

    validation_status?   : string

    created_at?     : string
    updated_at?     : string

    public constructor(object?: any){
        object                  = object || {}

        this.description        = object.description ? new CompanyDescription(object.description) : new CompanyDescription()
        this.municipalTerritory = object.municipalTerritory ? new CompanyMunicipalTerritory(object.municipalTerritory) : new CompanyMunicipalTerritory()
        this.siege              = object.siege ? new CompanySiege(object.siege) : new CompanySiege()
        this.workforce          = object.workforce ? new CompanyWorkforce(object.workforce) : new CompanyWorkforce()
        this.endOfActivity      = object.endOfActivity ? new CompanyEndofActivity(object.endOfActivity) : new CompanyEndofActivity()
        this.contacts           = object.contacts ? CompanyContactInformation.getFormsFromList(object.contacts) : CompanyContactInformation.getFormsFromList(null)
        this.rc_registration    = object.rc_registration ? new CompanyRCRegistration(object.rc_registration) : new CompanyRCRegistration()
        this.associates         = object.associates ? CompanyAssociate.getFormsFromList(object.associates) : CompanyAssociate.getFormsFromList(null)
        this.AVSno                = object.AVSno || ""
        this.familyAllowanceNo    = object.familyAllowanceNo || ""

        this.validation_status  = object.validation_status || "pending"

        if(!object.contacts || object.contacts.length < 1)
            this.contacts.push(new CompanyContactInformation())

        this._id                = object._id || null;
        this._company_id        = object._company_id || null;
        this.created_at         = object.created_at || null;
        this.updated_at         = object.updated_at || null;

        if (object.contacts && object.contacts[0].first_name) {
            if (object.contacts[0].last_name) {
                this.contact_full   = object.contacts[0].first_name + " " +  object.contacts[0].last_name;
            } else {
                this.contact_full   = object.contacts[0].first_name;
            }
        } else {
            if (object.contacts && object.contacts[0].last_name) {
                this.contact_full   = object.contacts[0].last_name;
            } else {
                this.contact_full   = " ";
            }
        }
    }

    public static getFormsFromList(objects: any): CompanyForm[]{
        if(objects && objects.length){
            return [...objects.map( (x:any) => new CompanyForm(x) )]
        }
        return []
    }

    public firstContactIsDirector(){
        if(this.contacts && this.contacts.length > 0){
            return (this.contacts[0].functions.includes("directeur"))
        }
        return false;
    }

    public firstContactIsChief(){
        if(this.contacts && this.contacts.length > 0){
            return (this.contacts[0].functions.includes("chef_du_personnel"))
        }
        return false;
    }

    public removeDirector(){
        if(this.contacts && this.contacts.length > 0)
        delete this.contacts[1];
    }

    public removeChief(){
        if(this.contacts && this.contacts.length > 1)
        delete this.contacts[2];
    }

    private removeSpecificFunction(key: string){
        this.contacts = [...this.contacts.filter((x: CompanyContactInformation, index:number) => {
            return !x.functions.includes(key) || index == 0
        })]
    }

    public municipalCompanyisSiege(){
        if(this.description && this.description.activity && this.description.activity.company_type && this.description.activity.company_type.length > 0){
            return (this.description.activity.company_type.includes("siege"))
        }
        return false;
    }
}

export class CompanyActivity {
    description  : string
    types        : TaxonomyTerm[]
    subTypes     : TaxonomyTerm[]
    company_type : string[]

    public constructor(object?: any){
        object                  = object || {}
        this.description        = object.description || "";
        this.types              = TaxonomyTerm.getTermsFromList(object.types);
        this.subTypes           = TaxonomyTerm.getTermsFromList(object.subTypes);
        this.company_type       = [...(object.company_type || [])]
    }

    public listAllTypes(){
        let allTypes = "";
        this.types.forEach(element => {
            allTypes += element.value + " ";
        });
        return allTypes;
    }
    public listAllSubTypes(){
        let allSubTypes = "";
        this.subTypes.forEach(element => {
            allSubTypes += element.value + " ";
        });
        return allSubTypes;
    }
    public listAllComTypes(){
        let allComTypes = "";
        this.company_type.forEach(element => {
            allComTypes += element + " ";
        });
        return allComTypes;
    }

}

export class CompanyCCT {
    state    : boolean
    name     : string

    public constructor(object?: any){
        object        = object || {}
        this.state    = object.state || false
        this.name     = object.name || ""
    }
}

export class CompanyDescription {
    taxpayerNo      : number;
    NOGANo          : string;
    BURNo           : number;
    socialReason    : string;
    legalForm       : TaxonomyTerm;
    activity        : CompanyActivity
    cct             : CompanyCCT
    newsletterSub   : boolean;
    enseigne        : string;

    public constructor(object?: any){
        object                  = object || {}
        this.taxpayerNo         = object.taxpayerNo;
        this.NOGANo             = object.NOGANo || "";
        this.BURNo              = object.BURNo;
        this.socialReason       = object.socialReason || "";
        this.legalForm          = new TaxonomyTerm(object.legalForm);
        this.activity           = new CompanyActivity(object.activity);
        this.cct                = new CompanyCCT(object.cct)
        this.newsletterSub      = object.newsletterSub || false;
        this.enseigne           = object.enseigne || "";
    }
}

export class CompanyMunicipalTerritory {
    activityStartDate   : Date
    phoneNumber         : string
    emailAddress        : string
    faxNumber           : string
    websiteURL          : string
    address             : string
    forAddress          : string
    premisesSurface     : number
    shareCapital        : number

    public constructor(object?: any){
        object                  = object || {}
        this.activityStartDate  = (object.activityStartDate) ? new Date(object.activityStartDate) : new Date();
        this.phoneNumber        = object.phoneNumber    || ""
        this.emailAddress       = object.emailAddress   || ""
        this.faxNumber          = object.faxNumber      || ""
        this.websiteURL         = object.websiteURL     || ""
        this.address            = object.address        || ""
        this.forAddress         = object.forAddress
        this.premisesSurface    = object.premisesSurface
        this.shareCapital       = object.shareCapital
    }
}

export class CompanySiege extends ContactInformation{
    sign        : string

    public constructor(object?: any){
        object       = object || {}
        super(object);

        this.sign   = object.sign  || ""
    }
}

export class Workforce {
    swiss       : number
    strangers   : number
    fulltime    : number
    parttime    : number
    parttime2   : number

    public constructor(object?: any){
        object            = object || {}
        this.swiss        = object.swiss        || 0
        this.strangers    = object.strangers    || 0
        this.fulltime     = object.fulltime     || 0
        this.parttime     = object.parttime     || 0
        this.parttime2    = object.parttime2    || 0
    }
}

export class Workpermits {
    B       : number
    C       : number
    L       : number
    other   : number

    public constructor(object?: any){
        object            = object || {}
        this.B        = object.B        || 0
        this.C        = object.C        || 0
        this.L        = object.L        || 0
        this.other    = object.other    || 0
    }
}

export class CompanyWorkforce {
    administration  : number
    production      : number
    men             : Workforce
    women           : Workforce
    permits         : Workpermits

    public constructor(object?: any){
        object                  = object || {}
        this.administration     = object.administration   || 0
        this.production         = object.production       || 0
        this.men                = new Workforce(object.men)
        this.women              = new Workforce(object.women)
        this.permits            = new Workpermits(object.permits)
    }
}

export class CompanyEndofActivity {
    date        : Date | null
    cause       : TaxonomyTerm
    description : string

    public constructor(object?: any){
        object            = object || {}
        this.date         = (object.date) ? new Date(object.date) : new Date();
        this.cause        = new TaxonomyTerm(object.cause)
        this.description  = object.description  || ""
    }
}

export class CompanyContactInformation extends ContactInformation{
    functions   : string[]
    title       : string
    last_name   : string
    first_name  : string
    other       : string

    public constructor(object?: any){
        object           = object || {}
        super(object);

        this.functions   = object.functions   || []
        this.title       = object.title       || "M"
        this.last_name   = object.last_name   || ""
        this.first_name  = object.first_name  || ""
        this.other       = object.other       || ""
    }

    public static getFormsFromList(objects: any): CompanyContactInformation[]{
        if(objects && objects.length){
            return [...objects.map( (x:any) => new CompanyContactInformation(x) )]
        }
        return []
    }

    public listAllFunc(){
        let allFunc = "";
        this.functions.forEach(element => {
            allFunc += element + " ";
        });
        return allFunc;
    }
}

export class CompanyRCRegistration{
    etat    : boolean
    numero  : string
    date    : Date

    public constructor(object?: any){
        object       = object || {}

        this.etat     = object.etat || false
        this.numero   = object.numero || ""
        this.date     = (object.date) ? new Date(object.date) : new Date();
    }
}

export class CompanyAssociate{
    firstName   : string
    lastName    : string
    home        : string

    public constructor(object?: any){
        object       = object || {}

        this.firstName  = object.firstName || ""
        this.lastName   = object.lastName || ""
        this.home       = object.home || ""
    }

    public static getFormsFromList(objects: any): CompanyAssociate[]{
        if(objects && objects.length){
            return [...objects.map( (x:any) => new CompanyAssociate(x) )]
        }
        return []
    }
}
