import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from 'views/Home.vue'
import Inscription from 'views/Inscription.vue'
import Login from 'views/Login.vue'
import AdministrationAccueil from 'views/Administration/Accueil.vue'
import SingleCompany from 'views/Administration/SingleCompany.vue'
import Historique from 'views/Administration/Historique.vue'
import SingleCompanyHistory from 'views/Administration/SingleCompanyHistory.vue'
import SentEmailsView from 'views/Administration/Email/SentEmails.vue'
import NewEmailView from 'views/Administration/Email/NewEmail.vue'
import Entreprise from 'views/Entreprise/Entreprise.vue'
import Employes from 'views/Entreprise/Employes.vue'
import ProfileView from 'views/Entreprise/Profile.vue'
import StatView from "views/Administration/Stat.vue"
import AuthGuard from 'guards/auth.guard'

import AuthService from "services/auth.service";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Accueil',
    component: Home
  },
  {
    path: '/inscription',
    name: 'Inscription',
    component: Inscription
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      AuthService.logout();
      next()
    }
  },
  {
    path: '/entreprise',
    name: 'Entreprise',
    beforeEnter: AuthGuard.Company,
    component: Entreprise
  },
  {
    path: '/entreprise/Profil',
    name: 'Profil',
    beforeEnter: AuthGuard.Company,
    component: ProfileView
  },
  {
    path: '/entreprise/employes',
    name: 'Employes',
    beforeEnter: AuthGuard.Company,
    component: Employes
  },
  {
    path: '/administration',
    name: 'Administration',
    beforeEnter: AuthGuard.Admin,
    component: AdministrationAccueil,
  },
  {
    path: '/administration/entreprises/:id',
    beforeEnter: AuthGuard.Admin,
    component: SingleCompany
  },
  {
    path: '/administration/mise-a-jour/historique',
    beforeEnter: AuthGuard.Admin,
    component: Historique
  },
  {
    path: '/administration/historique/:id',
    beforeEnter: AuthGuard.Admin,
    component: SingleCompanyHistory
  },
  {
    path: '/administration/emails/envoyes',
    beforeEnter: AuthGuard.Admin,
    component: SentEmailsView
  },
  {
    path: '/administration/emails/nouveau',
    beforeEnter: AuthGuard.Admin,
    component: NewEmailView
  },
  {
    path: '/administration/statistique',
    beforeEnter: AuthGuard.Admin,
    component: StatView
  },

  {
    path: '*', redirect: '/'
  },
]

const router = new VueRouter({
  mode    : 'history',
  base    : __dirname,
  routes
})

export default router
