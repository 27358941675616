







import { Component, Vue } from 'vue-property-decorator';
import FormulaireInscription from "components/FormulaireInscription.vue";

@Component({
  components: {
    FormulaireInscription,
  },
})
export default class Inscription extends Vue {}
