/* eslint-disable */

export default class MenuItem {
    label      : string;
    icon       : string;
    to          : string;
    command	    : Function;
    url	        : string;
    items	    : MenuItem[] | null;
    disabled	: boolean;
    visible	    : boolean | Function;
    target	    : string;
    separator	: boolean
    style	    : any;
    class	    : string;
    role        : string; //only has this role
    userCan     : string; //must posess this role
    tooltip     : string;

    public constructor(object?: any){
        object          = object || {}

        this.label      = object.label      || null;
        this.icon       = object.icon       || null;
        this.to         = object.to         || null;
        this.command	= object.command    || null;
        this.url	    = object.url	    || null;
        this.items	    = MenuItem.getItemsFromList(object.items);
        this.disabled   = object.disabled   || false;
        this.visible	= object.visible    || true;
        this.target	    = object.target	    || null;
        this.separator  = object.separator  || false;
        this.style	    = object.style	    || null;
        this.class	    = object.class	    || null;
        this.role       = object.role       || null;
        this.userCan    = object.userCan    || null;
        this.tooltip    = object.tooltip    || null;
    }


    public static hasChildrenWithPath(path: string, item: MenuItem): boolean{
        if(item.to == path)
            return true
        else if(item.items && item.items.length > 0){
             for(const it of item.items){
                if(MenuItem.hasChildrenWithPath(path, it))
                  return true;
            }
            return false;
        } else {
            return false
        }
    }

    public static getItemsFromList(objects: any): MenuItem[] | null{
        if(objects && objects.length){
            return [...objects.map( (x:any) => new MenuItem(x) )]
        }
        return null
    }
}