

import { Component, Vue } from "vue-property-decorator";
import { mapGetters} from 'vuex';
import Company from "models/Company";
import CompanyForm from "models/CompanyForm";
import { mixins } from "vue-class-component";
import TrsBasicView from "views/BasicView.vue";
import APIService from 'services/api.service';


@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    }),
    ...mapGetters('companies', {
      companyGetter: 'getOneByKey',
    })
  }
})
export default class ListeHistorique extends mixins(TrsBasicView) {
  filters = {};
  taxonomies!: any; // Getter from vuex for all taxonomies
  companyGetter!: any;
  company = new Company();
  companyHistory: null | CompanyForm[] = null;
  loading = false;
  entrepriseDialog = false;
  displayedData = "";
  i = 0;

  created(){
    this.company = this.companyGetter(this.$route.params.id);
    APIService.get('/companies/'+this.$route.params.id+'/forms').then(respone => {
      this.companyHistory = respone.data;
    });
    if(!this.company.form){
      this.loading = true;
      this.$router.push('/').then(() => this.$router.go(-1));
    }
    this.setTitle("Historique de "+this.company.form?.description.socialReason);
  }

  verif(liste: any, cherche: string) {
    let j: any;

    for (j in liste) {
      if (liste[j] == cherche) {
        return false;
      }
    }
    return true;
  }

    //date utility
  join(t, a, s) {
   function format(m) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
   }
   return a.map(format).join(s);
  }
  a = [{day: 'numeric'}, {month: 'numeric'}, {year: 'numeric'}];

  detailsEntreprise(elem: any) {
    this.displayedData = ""+this.modification(elem)[0];
    this.entrepriseDialog = true;
  }

  detailsEntrepriseInLine(event: any){
    this.detailsEntreprise({...event.data});
  }

  createdDate(elem: any){
    return (" "+this.join(new Date(elem.created_at), this.a, '/'));
  }
  getDate(elem: any){
    return " "+this.join(new Date(elem), this.a, '/');
  }

  getId(elem: any){
    let i = 0;
    let id = 0;
    this.companyHistory?.forEach((val) => {
      if(val._id == elem._id) {
        id = i;
      }
      i++;
    });
    return id;
  }

  version(elem: any){
    let id = this.getId(elem);
    let pos = id + 1;
    if (id == this.companyHistory!.length -1){
      return "Version " + pos + " (actuelle)";
    } else {
      return "Version " + pos;
    }
  }

  count_modif(elem: any){
    return this.modification(elem)[1];
  }

  format(prop: any, value: any, value2: any){
    let prop_clean = "NOT IMPLEMENTED : " + prop;
    switch (prop) {
      case '_id':
        prop_clean = "ID";
        break;
      case 'taxpayerNo':
        prop_clean = "N° contribuable";
        break;
      case 'NOGANo':
        prop_clean = "N° NOGA";
        break;
      case 'BURNo':
        prop_clean = "N° BUR";
        break;
      case 'socialReason':
        prop_clean = "Raison sociale";
        break;
      case 'legalForm':
        prop_clean = "Forme juridique";
        break;
      case 'description':
        prop_clean = "Description";
        break;
      case 'types':
        prop_clean = "Type d'activité"; // TO DO
        break;
      case 'subTypes':
        prop_clean = "Sous type d'activité";
        break;
      case 'company_type':
        prop_clean = "Type de companie";
        break;
      case 'state':
        prop_clean = "Soumise à une CCT";
        break;
      case 'name':
        prop_clean = "Convention";
        break;
      case 'newsletterSub':
        prop_clean = "Abonnement Newsletter";
        break;
      case 'enseigne':
        prop_clean = "Enseigne";
        break;
      case 'activityStartDate':
        prop_clean = "Début de l'activité";
        break;
      case 'phoneNumber':
        prop_clean = "Téléphone";
        break;
      case 'emailAddress':
        prop_clean = "Email";
        break;
      case 'faxNumber':
        prop_clean = "Fax";
        break;
      case 'websiteURL':
        prop_clean = "Site internet";
        break;
      case 'address':
        prop_clean = "Adresse";
        break;
      case 'title':
        prop_clean = "Titre";
        break;
      case 'forAddress':
        prop_clean = "P.A.";
        break;
      case 'premisesSurface':
        prop_clean = "Surf. des locaux";
        break;
      case 'shareCapital':
        prop_clean = "Capital social";
        break;
      case 'sign':
        prop_clean = "Numéro";
        break;
      case 'street':
        prop_clean = "Rue";
        break;
      case 'zip':
        prop_clean = "Code Postal";
        break;
      case 'city':
        prop_clean = "Localité";
        break;
      case 'country':
        prop_clean = "Pays";
        break;
      case 'mobile':
        prop_clean = "Mobile";
        break;
      case 'telephone':
        prop_clean = "Téléphone";
        break;
      case 'email':
        prop_clean = "Email";
        break;
      case 'fax':
        prop_clean = "Fax";
        break;
      case 'administration':
        prop_clean = "Nbre d'employé(s) dans l'administration";
        break;
      case 'production':
        prop_clean = "Nbre d'employé(s) de production";
        break;
      case 'swiss':
        prop_clean = "Suisses";
        break;
      case 'strangers':
        prop_clean = "Etrangers";
        break;
      case 'fulltime':
        prop_clean = "Travailleurs à temps complet (100%)";
        break;
      case 'parttime':
        prop_clean = "Travailleurs à temps partiel (50% ou plus)";
        break;
      case 'parttime2':
        prop_clean = "Travailleurs à temps partiel (moins de 50%)";
        break;
      case 'B':
        prop_clean = "Permis B";
        break;
      case 'C':
        prop_clean = "Permis C";
        break;
      case 'L':
        prop_clean = "Permis L";
        break;
      case 'other':
        prop_clean = "Autres permis";
        break;
      case 'date':
        prop_clean = "Date";
        break;
      case 'cause':
        prop_clean = "Cause de la fin d'activité";
        break;
      case 'first_name':
        prop_clean = "Prénom";
        break;
      case 'last_name':
        prop_clean = "Nom";
        break;
      case 'functions':
        prop_clean = "Fonctions";
        break;
      case 'etat':
        prop_clean = "Enregistré au registre";
        break;
      case 'numero':
        prop_clean = "N° au registre";
        break;
      case 'AVSno':
        prop_clean = "N° de la caisse de compensation AVS";
        break;
      case 'familyAllowanceNo':
        prop_clean = "N° de la caisse d'allocation familiale";
        break;
      case 'validation_status':
        prop_clean = "Status de validation";
        break;
      case '_company_id':
        prop_clean = "ID de l'entreprise";
        break;
      case 'updated_at':
        prop_clean = "Mis à jour le";
        break;
      case 'created_at':
        prop_clean = "Créé le";
        break;
    }
    switch (value){
      case true:
        value = 'Oui';
        break;
      case false:
        value = 'Non';
        break;
      case null:
      case undefined:
        value = "";
        break;
      case "pending_validation":
        value = "En attente de validation";
        break;
      case "validated":
        value = "Validée";
        break;
      case "created":
        value = "Brouillon";
        break;
    }
    switch (value2){
      case true:
        value2 = 'Oui';
        break;
      case false:
        value2 = 'Non';
        break;
      case null:
      case undefined:
        value2 = "";
        break;
      case "pending_validation":
        value2 = "En attente de validation";
        break;
      case "validated":
        value2 = "Validée";
        break;
      case "created":
        value2 = "Brouillon";
        break;
    }
    if(prop == "date" || prop == "updated_at" || prop == "created_at" || prop == "activityStartDate"){
      if (value != null && value != undefined && value != ""){
        value = this.getDate(value);
      }
      if (value2 != null && value2 != undefined && value2 != ""){
        value2 = this.getDate(value2);
      }
    }
    if (value2 == ""){
      return "<tr><td>" + prop_clean + "</td><td>" + value + "</td><td>" + value2 + "</td><tr>"
    } else {
      return "<tr style=\"color: brown; background-color: antiquewhite;\"><td>" + prop_clean + "</td><td>" + value + "</td><td>" + value2 + "</td><tr>"
    }
  }

  checkVal(value1, value){
    let count = 1;
    switch (value1) {
      case null:
        value1 = "";
        break;
      case undefined:
        value1 = "";
        break;
    }
    switch (value) {
      case null:
        value = "";
        break;
      case undefined:
        value = "";
        break;
    }
    if(value1 == value){
      count = 0;
    }
    return [value1, value, count];
  }

  manage_types(value: any, prop: any, value1: any, prop1: any, modif: string, count: number){
    if (value.length > 0 || value1.length > 0){
      let not_changed = [];
      let changed_new = [];
      let changed_old = [];
      //check if all value are in value 1
      for(const entry of value){
        let changed_bool = true;
        for(const entry1 of value1){
          if (entry.value == entry1.value){
            not_changed.push(entry.value);
            changed_bool = false;
          }
        }
        if (changed_bool){
          changed_old.push(entry.value);
        }
      }
      //check if all value1 are in value
      for(const entry1 of value1){
        let changed_bool = true;
        for(const entry of not_changed){
          if (entry == entry1.value){
            changed_bool = false;
          }
        }
        if (changed_bool){
          changed_new.push(entry1.value);
        }
      }
      if (changed_new.length > 0 || changed_old.length > 0){
        modif += "<tr style=\"color: brown; background-color: antiquewhite;\"><td>" + prop + " :</td><td></td><td></td><tr>";
        count += changed_new.length + changed_old.length;
      } else {
        modif += "<tr><td>" + prop + " :</td><td></td><td></td><tr>";
      }
      for(const entry of not_changed){
        modif += "<tr><td></td><td>" + entry +"</td><td></td><tr>";
      }
      for(const entry of changed_old){
        modif += "<tr style=\"color: brown; background-color: antiquewhite;\"><td></td><td></td><td>" + entry +"</td><tr>";
      }
      for(const entry of changed_new){
        modif += "<tr style=\"color: brown; background-color: antiquewhite;\"><td></td><td>" + entry +"</td><td></td><tr>";
      }
    }
    return [modif, count];
  }

  recursive_step(value: any, prop: any, value1: any, prop1: any, modif: string, deepness: number, count: number){
    if (prop == "types" || prop == "subTypes"){
      const temp = this.manage_types(value, prop, value1, prop1, modif, count);
      modif = ""+temp[0];
      count = temp[1] as number;
    }
    else if (value == "[object Object]" && value1 == "[object Object]"){
      if (deepness == 2){
        modif = modif + "</table><h" + deepness + ">" + prop + "</h" + deepness + "><table style=\"width:100%\"><tr><th style=\"width:30%\">Entrée</th><th style=\"width:35%\">Valeur</th><th style=\"width:35%\">Modification</th></tr>";
      }
      for (const [propDeep, valueDeep] of Object.entries(value as Record<string, any>)){
        if (propDeep != "label"){
          for (const [prop1Deep, value1Deep] of Object.entries(value1 as Record<string, any>)){
            if (propDeep == prop1Deep) {
              let cleanPropDeep = propDeep;
              switch(prop){
                case "legalForm":
                  cleanPropDeep = "legalForm";
                  break;
                /*case "types":
                  cleanPropDeep = "types";
                  break;
                case "subTypes":
                  cleanPropDeep = "subTypes";
                  break;*/
                case "cause":
                  cleanPropDeep = "cause";
                  break;
              }
              if (valueDeep != value1Deep) {
                const temp = this.recursive_step(valueDeep, cleanPropDeep, value1Deep, cleanPropDeep, modif, deepness+1, count);
                modif = ""+temp[0];
                count = temp[1] as number;
              } else {
                modif = modif + this.format(cleanPropDeep, valueDeep, "");
              }
            }
          }
        }
      }
    } else if (value == "[object Object]"){
      for (const [propDeep, valueDeep] of Object.entries(value as Record<string, any>)){
        if (propDeep != "label"){
          let cleanPropDeep = propDeep;
          switch(prop){
            case "legalForm":
              cleanPropDeep = "legalForm";
              break;
            /*case "types":
              cleanPropDeep = "types";
              break;
            case "subTypes":
              cleanPropDeep = "subTypes";
              break;*/
            case "cause":
              cleanPropDeep = "cause";
              break;
          }
          const temp = this.recursive_step(valueDeep, cleanPropDeep, value1, cleanPropDeep, modif, deepness+1, count);
          modif = ""+temp[0];
          count = temp[1] as number;
        }
      }
    } else if (value1 == "[object Object]"){
      for (const [propDeep1, valueDeep1] of Object.entries(value1 as Record<string, any>)){
        if (propDeep1 != "label"){
          let cleanPropDeep = propDeep1;
          switch(prop1){
            case "legalForm":
              cleanPropDeep = "legalForm";
              break;
            /*case "types":
              cleanPropDeep = "types";
              break;
            case "subTypes":
              cleanPropDeep = "subTypes";
              break;*/
            case "cause":
              cleanPropDeep = "cause";
              break;
          }
          const temp = this.recursive_step(value, cleanPropDeep, valueDeep1, cleanPropDeep, modif, deepness+1, count);
          modif = ""+temp[0];
          count = temp[1] as number;
        }
      }
    } else if (value == "" && value1 == "") {
      modif = modif + "";
    } else if (Array.isArray(value) || Array.isArray(value1)) {
      if (Array.isArray(value) && Array.isArray(value1)) {
        value.forEach((val, index) => {
          if(val == value1[index]){
            modif = modif + this.format(prop, val, "");
          } else if (val == "[object Object]" || value1[index] == "[object Object]"){
            const temp = this.recursive_step(val, prop, value1[index], prop1, modif, deepness+1, count);
            modif = ""+temp[0];
            count = temp[1] as number;
          } else {
            const temp = this.checkVal(value1[index], val);
            modif = modif + this.format(prop, temp[0], temp[1]);
            count += temp[2];
          }
        });
      } else if (Array.isArray(value)){
        value.forEach((val, index) => {
          if (val == "[object Object]"){
            const temp = this.recursive_step(val, prop, value1, prop1, modif, deepness+1, count);
            modif = ""+temp[0];
            count = temp[1] as number;
          } else {
            const temp = this.checkVal(value1, val);
            modif = modif + this.format(prop, temp[0], temp[1]);
            count += temp[2];
          }
        });
      } else {
        value1.forEach((val1, index) => {
          if (val1 == "[object Object]"){
            const temp = this.recursive_step(value, prop, val1, prop1, modif, deepness+1, count);
            modif = ""+temp[0];
            count = temp[1] as number;
          } else {
            const temp = this.checkVal(val1, value);
            modif = modif + this.format(prop, temp[0], temp[1]);
            count += temp[2];
          }
        });
      }
    } else {
      const temp = this.checkVal(value1, value);
      modif = modif + this.format(prop, temp[0], temp[1]);
      count += temp[2];
    }
    return [modif, count];
  }

  modification(elem: any) {
    let id = this.getId(elem);
    let modif = " ";
    let count = 0;
    if (id == 0){
      return ["Version initiale", count];
    } else {
      modif = modif + "<h1>" + this.version(elem) + "</h1><table style=\"width:100%\"><tr><th style=\"width:30%\">Entrée</th><th style=\"width:35%\">Valeur</th><th style=\"width:35%\">Modification</th></tr>";
      for (const [prop, value] of Object.entries(this.companyHistory![id])){
        for (const [prop1, value1] of Object.entries(this.companyHistory![id-1])){
          if (prop == prop1) {
            if (value != value1) {
              if (prop == "_id" || prop == "updated_at" || prop == "created_at"){
                modif = modif + this.format(prop, value, "");
              } else {
                const temp = this.recursive_step(value, prop, value1, prop1, modif, 2, count);
                modif = ""+temp[0];
                count = temp[1] as number;
              }
            } else {
              modif = modif + this.format(prop, value, "");
            }
          }
        }
      }
      modif = modif + "</table>";
    }
    return [modif.substring(1), count];
  }

  back() {
    this.$router.push('/administration/mise-a-jour/historique/');
  }

}
