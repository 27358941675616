

import { Component, Vue } from "vue-property-decorator";
import ListeAdministration from "components/Administration/ListeAdministration.vue";
import { mixins } from "vue-class-component";
import TrsBasicView from "views/BasicView.vue";
import Company from "models/Company";
import { mapGetters } from "vuex";
import APIService from "services/api.service";
import authService from "services/auth.service";
//import Editor from '../../../components/Editor.vue';
import Editor from 'primevue/editor';
import { AxiosResponse } from 'axios';


@Component({
  components: {
    ListeAdministration,
    Editor,
  },
  computed: {
    ...mapGetters('companies', {
      companyGetter: 'getOneByKey'
    })
  }
})
export default class SingleCompany extends mixins(TrsBasicView) {
  companyGetter!: any;
  company = new Company();
  scrollPosition = 0;
  touched = false;
  loading = false;

  importDialog = false;
  logo_src = null;

  created(){
    /*this.company = this.companyGetter(this.$route.params.id);
    if(!this.company.form){
      this.loading = true;
      this.$router.push('/').then(() => this.$router.go(-1));
    }
    this.setTitle(""+this.company.form?.description.socialReason);
    document.querySelector('#content')?.addEventListener('scroll', this.updateScroll);*/
    this.loading = !this.company;
    APIService.get('/companies/'+this.$route.params.id).then((response: AxiosResponse) =>{
                    if(response.status == 200){
                        this.company = Company.getCompanyFromList(response.data);
                        this.setTitle(""+this.company.form?.description.socialReason);
                        document.querySelector('#content')?.addEventListener('scroll', this.updateScroll);
                    } else {
                        this.$router.push('/');
                    }
                });
  }

  updateScroll() {
    const el = document.querySelector('#content');
    this.scrollPosition = el?.scrollTop ?? 0;
  }

  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  }

  saveForm() {
    APIService.post("/companies/"+this.company._id+"/forms/update", this.company.form);
    this.touched = false;
  }

  saveWeb() {
    APIService.post("/companies/"+this.company._id+"/update", this.company);
  }
  addLogo(){
    this.importDialog = true;
    /*this.$prompt("Entrez l'url du logo : ").then(text => {
      this.company.webPage.logo = text;
    });*/
  }
  confirmLogo(){
    this.importDialog = false;
    if(this.logo_src){
      const formData = new FormData();
      const config = {
                      headers: { 'Content-Type': 'multipart/form-data; charset=utf-8; boundary=---WebKitFormBoundary' + Math.random().toString().substr(2) }
                  }
      formData.append('logo', this.logo_src.files[0], 'file');
      formData.append('_id', this.company._id);
      APIService.post_withOptions("/companies/logo", formData, config).then( response => {
        if(response.status == 200){
          this.company.webPage.logo = "https://aigle.trisline.ch/api/logo/" + response.data.substr(response.data.lastIndexOf('/')+1, response.data.length);
        }
      });
      this.logo_src = null;
    }
  }
  requestUploadFile(){
    this.logo_src = this.$el.querySelector('#uploadmyfile')
  }

  back() {
    if(this.touched){
      this.$confirm("Voullez-vous vraiment revenir en arrière ?", "Attention, les données modifiées n'ont pas été sauvegardées !", "warning").then(() => {
        this.$router.push('/administration/');
    });
    } else {
      this.$router.push('/administration/');
    }
  }

  onTouched(){
    this.touched = true;
  }

}
