
import { Component, Vue } from "vue-property-decorator";
import { mapGetters} from 'vuex';
import Company from "models/Company";
import CompanyForm from "models/CompanyForm";
import APIService from "services/api.service";
import { AxiosResponse } from 'axios';


@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    }),
    ...mapGetters('companies', {
      companies: 'getAll',
      companyGetter: 'getOneByKey',
    })
  }
})
export default class ListeHistorique extends Vue {
  selectedEntreprise = null;
  entrepriseDialog = false;
  entreprise: Company|null = null;
  filters = {};
  taxonomies!: any; // Getter from vuex for all taxonomies
  companies!: any; // Getter from vuex for all companies
  companyGetter!: any;
  totalComp = 1;
  entreprises_quick = [];
  loading = 0;

  verif(liste: any, cherche: string) {
    let j: any;

    for (j in liste) {
      if (liste[j] == cherche) {
        return false;
      }
    }
    return true;
  }

    //date utility
  join(t, a, s) {
   function format(m) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
   }
   return a.map(format).join(s);
  }
  a = [{day: 'numeric'}, {month: 'numeric'}, {year: 'numeric'}];

  editEntreprise(entreprise: any) {
    this.$router.push('/administration/historique/'+entreprise._id);
  }

  editEntrepriseInLine(event: any){
    let entreprise = new Company({...event.data});
    this.$router.push('/administration/historique/'+entreprise._id);
  }

  createdDate(elem: any){
    return this.join(new Date(elem.created_at), this.a, '/');
  }

  modifDate(elem: any){
    return this.join(new Date(elem.form.updated_at), this.a, '/');
  }

  fetchData(){
    return APIService.get('companiesCount').then((response: AxiosResponse) =>{
      this.totalComp = response.data
      this.fetchDataStep(0)
    });
  }
  fetchDataStep(i: any){
    APIService.get('/companiesshortadm/' + i).then((response: AxiosResponse) =>{
          if (response.data == "DONE" || this.companies.length != 0){
            console.debug("Successfully fetched companies ");
          } else {
            this.entreprises_quick = this.entreprises_quick.concat(Company.getCompaniesFromList(response.data));
            this.fetchDataStep(i+1)
            this.loading += 20/this.totalComp*100
          }
        });
  }

  mounted(){
    this.fetchData();
  }

}
