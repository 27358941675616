import { AxiosResponse } from 'axios';
import { Store, Module} from 'vuex'
import APIService from './api.service';
import MailTemplate from 'models/MailTemplate';

interface MailTemplatesStoreIF {
    all: MailTemplate[];
    latestFetch: Date;
}

export default class MailTemplatesService {
    static API_URL = 'https://aigle.trisline.ch/api';

    private store: Store<any>;
    private storeModule: Module<any, any> =  {
        namespaced : true,
        state: () =>({
            all : [],
            latestFetch : Date,
        }),
        mutations: {
            setMailTemplates(state: MailTemplatesStoreIF, templates: MailTemplate[]){
                state.all = [...templates];
            },
        },
        actions: {
            refresh(context: any){
                if(localStorage.getItem("user")){
                    return APIService.get('/mails/templates').then((response: AxiosResponse) =>{
                        if(response.status == 200){
                            console.debug("Successfully fetched MailTemplates");
                            context.commit("setMailTemplates", MailTemplate.getMailTemplateFromList(response.data))
                        }
                    });
                }
            },
        },
        getters: {
            getAll: (state: MailTemplatesStoreIF) => {
                return state.all;
            },
        }
    }


    private constructor(store: Store<any>){
        store.registerModule('templates', this.storeModule);
        this.store = store;
        this.refresh();
    }

    public refresh(){
        return this.store.dispatch('templates/refresh')
    }

    static _instance: MailTemplatesService;

    public static init(store: Store<any>){
        if(!this._instance)
            this._instance = new MailTemplatesService(store);
        return this._instance;
    }

    public static get(store?: Store<any>): MailTemplatesService{
        if(!store && !this._instance){
            console.error("Trying to access MailTemplate service's instance without init first and passing store !")
            throw "Trying to access MailTemplate service's instance without init first and passing store !"
        } else if (this._instance){
            return this._instance;
        } else {
            return this.init(store!)
        }
    }
}
