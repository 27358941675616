
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company municipal infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { CompanyMunicipalTerritory } from "models/CompanyForm";
import { mapGetters } from "vuex";
import Calendar from 'primevue/calendar';



@Component
export default class CompanyTerritoryFormComponent extends Vue {
  touched = false;
  /* ----- v-model ----- */
  @VModel({ type: CompanyMunicipalTerritory }) territory!: CompanyMunicipalTerritory
  /* ------------------- */

  onTouched($event: boolean) {
    this.touched = $event;
    this.$emit("touched", this.territory);
  }

  sendCancel() {
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave() {
    this.$emit("save", this.territory);
    this.touched = false;
  }
}
