import axios from 'axios';
import User from 'models/User'
import JWT from "jwt-decode";
import RegistrationForm from 'models/RegistrationForm';
import APIService from './api.service';

class AuthService {
  user: User[] = [];

  login(user: User) {
    return APIService.post('/login', {
        username: user.username,
        password: user.password
      }).then(response => {
        if(response.status == 201){
          localStorage.setItem('double-auth', "required");
        } else {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
      });
  }

  loginDouble(user: User, doubleCode: number) {
    return APIService.post('/loginDouble', {
        username: user.username,
        password: user.password,
        code: doubleCode,
      }).then(response => {
        localStorage.setItem('user', JSON.stringify(response.data));
      });
  }

  getToken(){
    if(!localStorage.getItem("user"))
      return "";

    const json = JSON.parse(localStorage.getItem("user")!);
    return json.access_token;
  }

  getUser(): User{
    this.refreshUser();
    if(!localStorage.getItem("user")){
      return new User();
    }
    const json = JSON.parse(localStorage.getItem("user")!);
    return new User(json.user);
  }

  refreshUser() {
    let json = JSON.parse(localStorage.getItem("user")!);
    if(json){
      APIService.get('/me').then(response => {
        json.user = new User(response.data)
        localStorage.setItem('user', JSON.stringify(json));
      });
    }
  }

  protected getParsedToken(){
    const token = this.getToken();
    if(token && token != ""){
      const decoded = JSON.stringify(JWT(token));
      return JSON.parse(decoded);
    }

    return false;
  }

  getRoles(): string[]{
    const parse = this.getParsedToken();

    if(parse && parse.scopes && parse.scopes.length > 0){
      return parse.scopes;
    }
    return [];
  }

  getRole() {
    const roles = this.getRoles();
    if (roles && roles.length > 0) {
      //RFo : Greater role is last of array
      return roles[roles.length - 1];
    } else {
      return "public";
    }
  }

  //Does the user has in his roles this one ?
  userCan(role: string){
    if(role=="public") return true;
    const roles = this.getRoles();
    return roles.find(elem => elem.toLowerCase() == role.toLowerCase()) != undefined;
  }

  //Is user main role (higher one) equal to this one ?
  userRoleIs(role: string){
    return role.toLocaleLowerCase() == this.getRole().toLocaleLowerCase()
  }

  isAuthenticated(){
    return (localStorage.getItem("user") != null && localStorage.getItem("user") != undefined && this.getRoles().length > 0)
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(formValue: RegistrationForm) {
    return APIService.post('/register', formValue);
  }

  forgotPassword(email: string){
    APIService.post('/forgot-password', {email : email})
  }
}

export default new AuthService();
