
import { Component, Vue } from "vue-property-decorator";
import FormulaireEntreprise from "components/Entreprise/FormulaireEntreprise.vue";
import FormulaireEntrepriseForm from "components/Entreprise/FormulaireEntrepriseForm.vue";
import APIService from "services/api.service";
import { mapGetters } from "vuex";
import Company from "models/Company";
import { mixins } from "vue-class-component";
import TrsBasicView from "views/BasicView.vue";
import authService from "services/auth.service";
import { AxiosResponse } from 'axios';



@Component({
  components: {
    FormulaireEntrepriseForm,
  },
  computed: {
    ...mapGetters('companies', {
      companyGetter: 'getOneByKey'
    })
  }
})
export default class Entreprise extends mixins(TrsBasicView) {
  companyGetter!: any;
  company = null;
  companyStatus = "";
  statusStyle = "border-radius:20px; padding:8px;";


  mounted(){
    if(!authService.getUser().company_id){
      this.$router.push('/').then(() => this.$router.push('/entreprise'));
    }
    APIService.get('/companies/'+authService.getUser().company_id).then((response: AxiosResponse) =>{
        if(response.status == 200){
            this.company = Company.getCompanyFromList(response.data);
            this.setTitle(""+this.company.form?.description.socialReason);
            if(this.company.form?.validation_status=="validated"){
              this.companyStatus = "Validé";
              this.statusStyle += 'color:#446723; background-color:#AFD789';
            } else if(this.company.form?.validation_status=="pending_validation"){
              this.companyStatus = "En cours de validation";
              this.statusStyle += 'color:#236767; background-color:#9ADCDC';
            } else {
              this.companyStatus = "Brouillon";
              this.statusStyle += 'color:#8F8F31; background-color:#CBCB68';
            }
        } else {
            this.$router.push('/');
        }
    });
  }

  saveForm() {
    APIService.post("/companies/"+this.company._id+"/forms/update", this.company.form);
    this.$alert("Vos modifications ont bien été sauvegardées !", "Modifications Sauvegardées", "success")
  }

  saveWeb() {
    APIService.post("/companies/"+this.company._id+"/update", this.company);
    this.$alert("Vos modifications ont bien été sauvegardées !", "Modifications Sauvegardées", "success")
  }

}


