
import { Component, Vue } from 'vue-property-decorator';
import LoginForm from "components/LoginForm.vue";
import RegisterForm from "components/RegisterForm.vue";

@Component({
  components: {
    LoginForm,
    RegisterForm
  },
})
export default class Connexion extends Vue {
  subscribe = false;
  menuAnim = false;

  created(){
    setTimeout(()=>{
      this.menuAnim = true;
    })
  }

  onSubscribeClick(value: boolean){
    this.subscribe = value
  }
}
