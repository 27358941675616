
import Vue from "vue";
import Component from "vue-class-component";
import NavigationService from "services/nav.service"
import { mapGetters } from "vuex";
import authService from "services/auth.service";
import { Watch } from "vue-property-decorator";
import { Route } from "vue-router";

@Component({
  computed: {
    ...mapGetters('navigation', {
      current_page: 'getCurrentPage'
    })
  }
})
export default class TopbarComponent extends Vue {
  current_page!: any;
  hover = false;
  endedAnimation = false;
  needsToClose = false;
  userMenutItems = this.getDropdownMenuItem();

  getItems(){
    return NavigationService.get().getTopbarItems();
  }

  getWidth(){
    return  (this.$refs && this.$refs.sidebarItems ? (this.$refs.sidebarItems as Element).clientWidth : 100);
  }

  getUserAvater(){
    return authService.getUser().getInitials();
  }

  getDropdownMenuItem() {
    return [
        {
          label: authService.getUser().getFullname(),
        },
        {
          label: "Mon Profil",
          icon: "pi pi-fw pi-user",
          to: "/entreprise/profil",
        },
        {
          label   : "Deconnexion",
          icon    : "pi pi-fw pi-sign-out",
          to      : "/login"
        },
      ]
  }

  toggleUserMenu(event: any) {
    (this.$refs.userMenu as any).toggle(event);
  }

  isLoggedIn(){
    return authService.getRoles().length > 0
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChanged(to: Route, from: Route) {
    this.$forceUpdate()
    NavigationService.get().refresh();
    this.userMenutItems = this.getDropdownMenuItem();
  }


}
