
import { Component, Vue } from "vue-property-decorator";
import RegistrationForm from "models/RegistrationForm";
import APIService from "services/api.service";
import authService from "services/auth.service";


@Component
export default class RegisterForm extends Vue {

  formValue = new RegistrationForm();
  formHasErrors = false;
  message = "";
  usernameAvailable: boolean|null = null;
  usernameError: boolean|null = null;
  mailError: boolean|null = null;
  mediumRegex = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})' // eslint-disable-line
  strongRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})' // eslint-disable-line
  returnVal: any;
  formEnd = false;
  //usernameErrorMessage = "no";

  async handleRegister() {
    if(this.checkFormValidity()){
      this.formHasErrors = false;
      this.returnVal = await authService.register(this.formValue);
      if(this.returnVal.status == 200){
        this.formEnd = true;
      }
    } else {
      this.formHasErrors = true;
    }
  }

  passwordConfValidity(){
    return this.formValue.user.password == this.formValue.user.passwordConfirmation;
  }

  checkFormValidity(){
    return this.passwordConfValidity() && this.usernameAvailable;
  }

  checkUserValidity(){
    this.usernameError = false;
    this.mailError = false;
    this.usernameAvailable = false;
    if(this.formValue.user.username.length > 3){
      APIService.post("/users/check-availability", this.formValue.user).then((res: any)=> {
        if(res.status == 200)
          this.usernameAvailable = true;
      }).catch( (err: any)=> {
          this.usernameAvailable = false;
          if(err.response.data.error == "Username already in use"){
            this.usernameError = true;
          } else if(err.response.data.error == "Email Address already in use") {
            this.mailError = true;
          }
      })
    }
  }

  sendLogin(){
    this.$emit("subscribe", false);
  }

  zipSuggestions = [];
  cityList = {"1860": "Aigle", "1801": "Test"};

  checkCity(event){
    this.zipSuggestions = ['1860'];
  }
  autoCompleteCity(event){
    console.log("AHAHA");
    this.formValue.company.address.city = this.cityList['1860'];
  }
}
