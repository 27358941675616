
import { Component, Vue } from "vue-property-decorator";
import {Entreprise} from "models/Entreprise"
import { mapGetters } from "vuex";
import Company from "models/Company";
import APIService from 'services/api.service';
import { AxiosResponse } from 'axios';

@Component({
  computed: {
    ...mapGetters('companies', {
      companies: 'getAll',
    })
  }
})
export default class CompanyPublicList extends Vue {
  companies!: any;
  entrepriseDialog = false;
  entreprise: Company|null = null;
  entreprises_quick = [];
  filters = {};

  cols = [
    { field: 'raisonSoc', header: 'Enseigne' },
    { field: 'activite', header: 'Domaine d\'activité' },
    { field: 'adresse', header: 'Adresse' },
    { field: 'tel', header: 'Téléphone' }
  ];

  verif(liste: any) {
    let j: any;

    for (j in liste) {
        return j;
    }

  }

  onRowSelect(event: any) {
    this.entreprise = new Company({...event.data});
    this.entrepriseDialog = true;
  }

  refreshList() {
    this.$router.push('/inscription').then(() => this.$router.push('/'));
  }

  fetchData(){
    return APIService.get('/').then((response: AxiosResponse) =>{
      this.fetchDataStep(0)
    });
  }
  fetchDataStep(i: any){
    APIService.get('/companiesshort/' + i).then((response: AxiosResponse) =>{
          if (response.data == "DONE" || this.companies.length != 0){
            console.debug("Successfully fetched companies ");
          } else {
            this.entreprises_quick = this.entreprises_quick.concat(Company.getCompaniesFromList(response.data));
            this.fetchDataStep(i+1)
          }
        });
  }

  mounted(){
    this.fetchData();
  }

}
